<template>
  <div>
    <h5>{{ solarModule.name }}</h5>
    <div v-for="[name, value] in fields" :key="`sModId${solarModule.uuid}-attr${name}`">
      <span><b>{{name}}:</b> {{value}}</span>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
  name: 'SolarModuleInfo',
  props: {
    solarModule: {
      type: Object,
    }
  },
  computed: {
    getGroupByUuid: get('groups/getGroupByUuid'),
    fields() {
      const { moduleDesign, attributes, serialNumber, linkedDaq, groupUuid } = this.solarModule;
      const fields = [];
      if (linkedDaq) fields.push(['Linked DAQ', linkedDaq]);
      if (serialNumber) fields.push(['Serial Number', serialNumber]);
      if (moduleDesign && moduleDesign.name) fields.push(['Module Design', moduleDesign.name]);
      const group = this.getGroupByUuid(groupUuid);
      if (group) fields.push(['Group', group.name]);
      if (attributes) fields.push(...Object.entries(attributes));
      return fields;
    }
  }
};
</script>
