/* global L */

import './L.CustomSVGRenderer';

L.CustomSVG = L.Layer.extend({

  options: {
    angle: 0.0
  },

  initialize(svgElement, bounds, sizeAtRest, options) {
    this._svgElement = svgElement;
    this._bounds = bounds;
    this._center = this._bounds.getCenter();
    this._sizeAtRest = sizeAtRest;
    L.setOptions(this, options);
    this._initSVGElement(this._svgElement);
  },

  _initSVGElement(svgElement) {
    if (this.options.className) {
      L.DomUtil.addClass(svgElement, this.options.className);
    }
    if (this.options.interactive) {
      L.DomUtil.addClass(svgElement, 'leaflet-interactive');
    }
  },
  getMap(){
    return this._map;
  },
  beforeAdd(map) {
    this._renderer = map.getCustomSVGRenderer();
  },

  onAdd() {
    this._reset();
    this._renderer._addSVGElement(this);
  },

  onRemove() {
    this._renderer._removeSVGElement(this);
  },

  getEvents() {
    const events = {
      zoom: this._reset,
      viewreset: this._reset
    };

    return events;
  },

  redraw() {
    if (this._map) {
      this._reset();
    }

    return this;
  },

  _reset() {
    const svgElement = this._svgElement;
    const bounds = new L.Bounds(this._map.latLngToLayerPoint(this._bounds.getNorthWest()), this._map.latLngToLayerPoint(this._bounds.getSouthEast()));
    const size = bounds.getSize();

    const scaleX = size.x / this._sizeAtRest.width;
    const scaleY = size.y / this._sizeAtRest.height;

    svgElement.setAttribute('transform', `translate(${bounds.min.x} ${bounds.min.y}) scale(${scaleX} ${scaleY}) rotate(${this.options.angle})`);
  },

  bringToFront() {
    if (this._renderer) {
      this._renderer._bringToFront(this);
    }

    return this;
  },

  bringToBack() {
    if (this._renderer) {
      this._renderer._bringToBack(this);
    }

    return this;
  },

  getLatLng() {
    return this._center;
  },

  setSVGElementStyle(className, style) {
    if (this._svgElement != null) {
      const elements = this._svgElement.querySelectorAll(`.${className}`);
      elements.forEach(el => el.setAttribute('style', style));
    }
  }
});

L.customSVG = (svgElement, bounds, sizeAtRest, options) => {
  return new L.CustomSVG(svgElement, bounds, sizeAtRest, options);
};
