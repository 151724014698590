<template>
  <svg xmlns="http://www.w3.org/2000/svg">
    <g v-for="cluster in clusters" :key="cluster.id">
      <cluster
        :cluster="cluster"
        :valueData="clusterValues[cluster.id]"
        :valueType="valueType"
        @click="handleClusterClick">
        <l-tooltip v-if="infoActive">
          <cluster-info :cluster="cluster" :valueData="clusterValues[cluster.id]" :valueType="valueType" />
        </l-tooltip>
      </cluster>
    </g>
  </svg>
</template>

<script>
import { LTooltip } from 'vue2-leaflet';
import Cluster from './Cluster.vue';
import ClusterInfo from './ClusterInfo.vue';

export default {
  name: 'Clusters',
  components: {
    LTooltip,
    Cluster,
    ClusterInfo
  },
  props: {
    clusters: Array,
    clusterValues: Object,
    valueType: String,
    infoActive: Boolean
  },
  methods: {
    handleClusterClick(e, cluster) {
      this.$emit('clusterClick', cluster);
    }
  },
  mounted(){
    if (this.$el.parentNode) this.$el.parentNode.removeChild(this.$el);
  }
};
</script>
