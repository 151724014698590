<template>
  <div>
    <div class="d-flex align-items-center py-2 px-2">
      <div class="flex-grow-1 d-flex flex-row align-items-center">
        <h1 class="display-1 site-status-site m-0">{{ site ? site.name : null }}</h1>
        <div v-if="numGateway > 0">
          <div id="gateway-status" class="site-status-badge px-2" style="margin-top: 2px;">
            <font-awesome-icon icon="exclamation-circle" :style="gatewayStatusStyle" size="lg" v-if="!allGatewaysOnline" />
            <font-awesome-icon icon="check-circle" :style="gatewayStatusStyle" size="lg" v-else />
          </div>
          <b-tooltip target="gateway-status" triggers="hover">
            <div v-for="gateway in currentGatewayStatus" :key="gateway.code">
              <b>{{ gateway.name }}:</b> {{ gateway.status | getReadableGatewayStatus }}
            </div>
          </b-tooltip>
        </div>
      </div>

      <div class="site-status-settings-icon p-1" @click="showSettings = true" v-if="canChangeSiteSettings">
        <font-awesome-icon icon="cog" size="lg" />
      </div>
    </div>

    <div class="px-2 pb-2">
      <b-row no-gutters>
        <b-col cols="6" class="d-flex flex-column">
          <div class="site-status-info flex-grow-1">{{$t('site_status.current_power')}}</div>
          <div class="site-status-val">{{ currentPower | formatPower }}</div>
        </b-col>
        <b-col cols="6" class="d-flex flex-column">
          <div class="site-status-info flex-grow-1">{{$t('site_status.energy_today')}}</div>
          <div class="site-status-val">{{ siteStatus.todayEnergy | formatEnergy }}</div>
        </b-col>
        <b-col cols="6" class="d-flex flex-column">
          <div class="site-status-info flex-grow-1">{{$t('site_status.months_energy')}}</div>
          <div class="site-status-val">{{ siteStatus.monthEnergy | formatEnergy }}</div>
        </b-col>
        <b-col cols="6" class="d-flex flex-column">
          <div class="site-status-info flex-grow-1">{{$t('site_status.total_energy')}}</div>
          <div class="site-status-val">{{ siteStatus.totalEnergy | formatEnergy }}</div>
        </b-col>
      </b-row>
    </div>

    <div class="site-status-footer py-1 px-2">
      <div class="d-flex flex-row flex-wrap mx-n2">
        <h1 class="display-1 site-status-footer-text site-status-footer-location flex-grow-1 mx-2 my-0">{{ locationString }}</h1>
        <h1 class="display-1 site-status-footer-text site-status-footer-time mx-2 my-0">{{ currentTime }}</h1>
      </div>
    </div>

    <div v-if="loading" class="site-status-loading d-flex justify-content-center align-items-center">
      <msi-spinner class="ml-1 no-select" :size="30" />
      <div class="ml-2">Loading Site Status Data</div>
    </div>

    <b-modal v-model="showSettings" hide-footer title="Settings">
      <site-settings />
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { get } from 'vuex-pathify';
import { BTooltip, BRow, BCol, BModal } from 'bootstrap-vue';

import SiteSettings from './SiteSettings.vue';
import MsiSpinner from './MsiSpinner.vue';

let interval = null;

export default {
  name: 'SiteStatus',
  components: {
    MsiSpinner,
    BTooltip,
    BRow,
    BCol,
    BModal,
    SiteSettings
  },
  props: {
    site: {
      type: Object
    },
    siteStatus: {
      type: Object
    },
    currentPower: {
      type: Number
    },
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      currentTime: null,
      showSettings: false
    };
  },
  computed: {
    selectedSite: get('sites/selectedSite'),
    siteGateways: get('gateways/getSiteGateways'),
    gatewayStatus: get('gateways/getGatewayStatus'),
    currentGatewayStatus() {
      if (!this.selectedSite) return [];
      const gateways = this.siteGateways(this.selectedSite.id);
      return gateways.map(gateway => ({ ...gateway, status: this.gatewayStatus(gateway.code) }));
    },
    numGateway() {
      if (this.currentGatewayStatus.length > 0) return true;
      return false;
    },
    gatewayStatusStyle() {
      const disconnected = this.currentGatewayStatus.find(({ status }) => status === 'SERVER DISCONNECTED');
      if (disconnected) return { color: 'grey' };

      const onlineCount = this.currentGatewayStatus.filter(({ status }) => status === 'ONLINE').length;
      const offlineCount = this.currentGatewayStatus.filter(({ status }) => status === 'OFFLINE').length;
      if (onlineCount === this.currentGatewayStatus.length) return { color: '#37872d' };
      if (offlineCount === this.currentGatewayStatus.length) return { color: 'red' };
      return { color: '#f47216' };
    },
    location() {
      if (!this.site || !this.site.location) return { city: '', state: '', country: '' };
      const locationParts = this.site.location.replace(/\s*,\s*/g, ',').split(',');
      return {
        city: locationParts[0] || '',
        state: locationParts[1] || '',
        country: locationParts[2] || ''
      };
    },
    locationString() {
      const { city, state } = this.location;
      if (city && state) return `${city}, ${state}`;
      if (city) return city;
      if (state) return state;
      return '';
    },
    allGatewaysOnline() {
      const onlineCount = this.currentGatewayStatus.filter(({ status }) => status === 'ONLINE').length;
      if (onlineCount === this.currentGatewayStatus.length) return true;
      return false;
    },
    canChangeSiteSettings() {
      if (!this.selectedSite) return false;
      return this.$kauth.hasSiteRole(this.selectedSite.id, 'operator');
    }
  },
  filters: {
    formatEnergy(energy) {
      if (energy === 0) return `${energy} Wh`;
      if (energy / 1000000000 >= 1) return `${(energy / 1000000000).toFixed(2)} GWh`;
      if (energy / 1000000 >= 1) return `${(energy / 1000000).toFixed(2)} MWh`;
      if (energy / 1000 >= 1) return `${(energy / 1000).toFixed(2)} kWh`;
      return `${energy.toFixed(2)} Wh`;
    },
    formatPower(power) {
      if (power === 0) return `${power} W`;
      if (power / 1000000000 >= 1) return `${(power / 1000000000).toFixed(2)} GW`;
      if (power / 1000000 >= 1) return `${(power / 1000000).toFixed(2)} MW`;
      if (power / 1000 >= 1) return `${(power / 1000).toFixed(2)} kW`;
      return `${power.toFixed(2)} W`;
    },
    getReadableGatewayStatus(status) {
      if (status === 'PENDING') return 'Pending';
      if (status === 'SERVER DISCONNECTED') return 'Server Disconnected';
      if (status === 'ONLINE') return 'Online';
      return 'Offline';
    }
  },
  watch: {
    site: {
      immediate: true,
      handler(site) {
        if (site && site.timezone) {
          this.currentTime = moment().tz(site.timezone).format('h:mm A z');

          if (interval != null) {
            clearInterval(interval);
            interval = null;
          }

          interval = setInterval(() => {
            this.currentTime = moment().tz(site.timezone).format('h:mm A z');
          }, 1000);
        } else {
          this.currentTime = null;
        }
      }
    }
  },
  beforeDestroy() {
    if (interval != null) {
      clearInterval(interval);
      interval = null;
    }
  }
};
</script>

<style>
.site-status-site {
  font-size: 1.5rem;
}

.site-status-badge {
  cursor: pointer;
}

.site-status-info {
  text-align: center;
  font-size: 1.1rem;
}

.site-status-val {
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  color: #37872d;
}

.site-status-footer {
  background-color: rgba(0, 0, 0, 0.774);
}

.site-status-footer-text {
  color: #ffffff;
  font-size: 1.3rem;
}

.site-status-footer-location {
  overflow: hidden;
}

.site-status-footer-time {
  white-space: nowrap;
}

.site-status-loading {
  background: rgba(0, 0, 0, 0.774);
  color: white;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 10;
}

.site-status-settings-icon {
  color: #555;
  cursor: pointer;
}

.site-status-settings-icon:hover {
  color: #777;
}

.no-select {
  user-select: none;
}

</style>
