<template>
  <div class="container">
    <b-row class="m-0" no-gutters>
      <b-col cols="auto"><h5 class="my-2 mr-2">Gradient Limits</h5></b-col>
      <b-col cols="auto">
        <multiselect
          id="type-select"
          v-model="selectedType"
          :options="Object.keys(selectedGradientTypes)"
          selectLabel=""
          deselectLabel=""
          placeholder="Module Design"
          :allowEmpty="false"
          class="h-75"
        />
      </b-col>
    </b-row>
    <b-row id="gradient-settings" :class="[isMobile ? 'mt-5' : 'mt-4', 'm-0', 'align-items-center', 'w-100']" no-gutters>
      <b-col sm="auto" md="2" class="px-2" v-if="!isMobile">
        <b-input-group append="Wh" size="sm">
          <b-form-input :value="minGradientValue" type="number" step="5" @change="updateMin"/>
        </b-input-group>
      </b-col>

      <b-col sm="8" md="8" class="px-3 my-2"><div id="slider" ref="slider"></div></b-col>

      <b-col sm="auto" md="2" class="px-2" v-if="!isMobile">
        <b-input-group append="Wh" size="sm">
          <b-form-input :value="maxGradientValue" type="number" step="5" @change="updateMax"/>
        </b-input-group>
      </b-col>
    </b-row>
    <div class="mt-4 m-0 d-flex justify-content-around">
      <b-button variant="primary" aria-label="reset" @click="setToDefault">Reset Default</b-button>
      <b-button variant="primary" aria-label="fit" @click="setToMax">Fit to min/max</b-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable prefer-destructuring */
import Multiselect from 'vue-multiselect';
import * as NoUiSlider from 'nouislider';
import { BRow, BCol, BInputGroup, BFormInput, BButton } from 'bootstrap-vue';

export default {
  components: {
    Multiselect,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BButton
  },
  props: {
    gradientTypes: {
      type: Object,
    },
  },
  data() {
    const types = Object.keys(this.gradientTypes);
    const typesCopy = types.reduce((acc, key) => {
      const typeCopy = { ...this.gradientTypes[key] };
      return { ...acc, [key]: { ...typeCopy } };
    }, {});
    return {
      selectedType: types.length && types[0],
      selectedGradientTypes: typesCopy,
    };
  },
  computed: {
    minGradientValue() {
      return this.selectedGradientTypes[this.selectedType].min;
    },
    maxGradientValue() {
      return this.selectedGradientTypes[this.selectedType].max;
    },
    isMobile() {
      return this.$feature.mobile;
    }
  },
  methods: {
    setToDefault() {
      const { nprMax } = this.gradientTypes[this.selectedType];
      this.updateSlider([0, nprMax]);
    },
    setToMax() {
      const { _max: max } = this.gradientTypes[this.selectedType];
      this.updateSlider([0, max]);
    },
    updateMin(e) {
      this.updateSlider([e, this.maxGradientValue]);
    },
    updateMax(e) {
      this.updateSlider([this.minGradientValue, e]);
    },
    updateSlider(limits) {
      this.updateRange(limits);
      this.$refs.slider.noUiSlider.set(limits);
    },
    updateRange(limits) {
      this.$refs.slider.noUiSlider.updateOptions({
        range: {
          min: 0,
          max: Math.max(this.gradientTypes[this.selectedType].nprMax || 0, limits[1], 750),
        }
      });
    },
    createSlider() {
      NoUiSlider.create(this.$refs.slider, {
        start: [this.minGradientValue, this.maxGradientValue],
        format: {
          to: val => val.toFixed(0),
          from: val => Number(val),
        },
        tooltips: this.isMobile,
        step: 5,
        connect: true,
        range: {
          min: 0,
          max: Math.max(this.gradientTypes[this.selectedType].nprMax || 0, this.maxGradientValue, 750),
        }
      });
      const connect = this.$refs.slider.getElementsByClassName('noUi-connect');
      if (connect.length) {
        connect[0].style.background = 'linear-gradient(to right, #808080, green, #6CD129)';
      }

      this.$refs.slider.noUiSlider.on('update', (values, handle) => {
        this.selectedGradientTypes[this.selectedType][handle ? 'max' : 'min'] = parseInt(values[handle]);
      });
    }
  },
  mounted() {
    this.createSlider();
  },
  watch: {
    selectedType() {
      this.updateSlider([this.minGradientValue, this.maxGradientValue]);
    },
    isMobile(isMobile) {
      this.$refs.slider.noUiSlider.updateOptions({ tooltips: isMobile });
    }
  }
};
</script>

<style lang="scss">
  @import '~nouislider/dist/nouislider.css';
</style>
