/* global L */

import './L.CustomSVGRenderer';

L.FixedCustomSVG = L.Layer.extend({

  initialize(svgElement, center, size) {
    this._svgElement = svgElement;
    this._center = center;
    this._size = size;
    this._initSVGElement(this._svgElement);
  },

  _initSVGElement(svgElement) {
    if (this.options.className) {
      L.DomUtil.addClass(svgElement, this.options.className);
    }
    if (this.options.interactive) {
      L.DomUtil.addClass(svgElement, 'leaflet-interactive');
    }
  },
  getMap(){
    return this._map;
  },
  beforeAdd(map) {
    this._renderer = map.getCustomSVGRenderer();
  },

  onAdd() {
    this._reset();
    this._renderer._addSVGElement(this);
  },

  onRemove() {
    this._renderer._removeSVGElement(this);
  },

  getEvents() {
    const events = {
      zoom: this._reset,
      viewreset: this._reset
    };

    return events;
  },

  redraw() {
    if (this._map) {
      this._reset();
    }

    return this;
  },

  _reset() {
    const svgElement = this._svgElement;
    const scaleX = this._size.width;
    const scaleY = this._size.height;
    const center = this._map.latLngToLayerPoint(this._center);
    svgElement.setAttribute('transform', `translate(${center.x} ${center.y}) scale(${scaleX} ${scaleY})`);
  },

  bringToFront() {
    if (this._renderer) {
      this._renderer._bringToFront(this);
    }

    return this;
  },

  bringToBack() {
    if (this._renderer) {
      this._renderer._bringToBack(this);
    }

    return this;
  },

  getLatLng() {
    return this._center;
  },

  setSVGElementStyle(className, style) {
    if (this._svgElement != null) {
      const elements = this._svgElement.querySelectorAll(`.${className}`);
      elements.forEach(el => el.setAttribute('style', style));
    }
  }
});

L.fixedCustomSVG = (svgElement, center, size) => {
  return new L.FixedCustomSVG(svgElement, center, size);
};
