<template>
  <div class="d-flex flex-row align-items-center justify-content-start justify-content-lg-end pt-2 pt-2 pt-lg-0">
    <div class="d-flex flex-row align-items-center pr-2 date-btns" :class="{ 'date-btns-disabled': disabledPrevNext }" @click="handlePrevNext(-1)">
      <font-awesome-icon icon="chevron-left" class="date-icon" />
      <h6 class="m-0 pl-1 date-btns-text">{{ $t('site_map.previous') }}</h6>
    </div>

    <div v-show="timeline !== 'month' && timeline !== 'year'">
      <flat-pickr class="summary-date" :value="flatPickrDate" :config="dateConfig" :disabled="disabledDate" @input="handleDateInput" />
    </div>

    <div v-show="timeline === 'month'">
      <flat-pickr class="summary-date" :value="flatPickrDate" :config="dateMonthConfig" :disabled="disabledDate" @input="handleDateInput" />
    </div>

    <input
      v-if="timeline === 'year'"
      type="number"
      :value="year"
      class="summary-date flatpickr-input"
      :disabled="disabledDate"
      @input="handleYearInput" />

    <div class="d-flex flex-row align-items-center pl-2 date-btns" :class="{ 'date-btns-disabled': disabledNext }" @click="handlePrevNext(1)">
      <h6 class="m-0 pr-1 date-btns-text">{{ $t('site_map.next') }}</h6>
      <font-awesome-icon icon="chevron-right" class="date-icon" />
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import moment from 'moment-timezone';

export default {
  name: 'DateControls',
  components: {
    flatPickr
  },
  props: {
    value: Date,
    timezone: String,
    timeline: {
      type: String,
      required: true
    },
    disabled: Boolean
  },
  data() {
    this.$options.monthSelector = new MonthSelectPlugin({ dateFormat: 'Y-m-d H:i:S', altFormat: 'M Y' });
    return {
      now: null,
      flatPickrDate: null
    };
  },
  computed: {
    localTimezone() {
      return this.timezone || 'UTC';
    },
    dateConfig() {
      return {
        maxDate: moment(this.now).tz(this.localTimezone).format('YYYY-MM-DD'),
        dateFormat: 'Y-m-d H:i:S',
        altFormat: 'M j, Y',
        altInput: true,
        altInputClass: 'summary-date flatpickr-input',
        disableMobile: true
      };
    },
    dateMonthConfig() {
      return {
        maxDate: moment(this.now).tz(this.localTimezone).format('YYYY-MM-DD'),
        altInput: true,
        altInputClass: 'summary-date flatpickr-input',
        plugins: [this.$options.monthSelector],
        disableMobile: true
      };
    },
    disabledDate() {
      return this.disabled || this.timeline === 'current_power';
    },
    disabledPrevNext() {
      return this.disabledDate || !this.value;
    },
    disabledNext() {
      if (this.disabledPrevNext) return true;
      const now = moment(this.now).tz(this.localTimezone);
      const date = moment(this.value).tz(this.localTimezone);
      if (this.timeline === 'day') date.add(1, 'days').startOf('day');
      else if (this.timeline === 'week') date.add(7, 'days').startOf('isoWeek');
      else if (this.timeline === 'month') date.add(1, 'months').startOf('month');
      else if (this.timeline === 'year') date.add(1, 'years').startOf('year');

      if (date.isSameOrAfter(now)) return true;
      return false;
    },
    year() {
      if (this.value) return moment(this.value).tz(this.localTimezone).year();
      return null;
    }
  },
  methods: {
    handlePrevNext(unit) {
      const now = moment(this.now).tz(this.localTimezone);
      const date = moment(this.value).tz(this.localTimezone);
      if (this.timeline === 'day') date.add(unit, 'days');
      else if (this.timeline === 'week') date.add(unit * 7, 'days');
      else if (this.timeline === 'month') date.add(unit, 'months');
      else if (this.timeline === 'year') date.add(unit, 'years');

      const isSameOrAfter = date.isSameOrAfter(now);
      if (isSameOrAfter) this.$emit('input', now.toDate());
      else this.$emit('input', date.toDate());
    },
    handleYearInput(event) {
      const year = Number.parseInt(event.target.value);
      if (year >= 1970 && year <= 3000) {
        const now = moment(this.now).tz(this.localTimezone);
        if (year > now.year()) return;
        const date = this.value ? moment(this.value).tz(this.localTimezone) : moment(this.now).tz(this.localTimezone);
        this.$emit('input', date.year(year).toDate());
      }
    },
    syncFlatPickrDate() {
      this.flatPickrDate = this.value ? moment(this.value).tz(this.localTimezone).format('YYYY-MM-DD') : null;
    },
    handleDateInput(input) {
      if (!input && this.flatPickrDate) {
        this.$emit('input', null);
      } else if (input) {
        const momentInput = moment.tz(input, this.localTimezone);
        if (this.flatPickrDate) {
          const momentFlatPickrDate = moment.tz(this.flatPickrDate, this.localTimezone);
          if (this.timeline === 'week' && momentFlatPickrDate.isSame(momentInput, 'day')) return;
          if (this.timeline !== 'week' && momentFlatPickrDate.isSame(momentInput, this.timeline)) return;
        }

        if (this.value) {
          const date = moment(this.value).tz(this.localTimezone);
          if (this.timeline === 'month') {
            const month = momentInput.month();
            momentInput.date(date.date()).month(month);
          }

          momentInput.hours(date.hours());
          momentInput.minutes(date.minutes());
          momentInput.seconds(date.seconds());
          momentInput.milliseconds(date.milliseconds());
        }

        this.$emit('input', momentInput.toDate());
      }
    }
  },
  created() {
    this.now = new Date();
    this.$options.nowUpdateInterval = setInterval(() => {
      this.now = new Date();
    }, 1000 * 60);
  },
  beforeDestroy() {
    if (this.$options.nowUpdateInterval) clearInterval(this.$options.nowUpdateInterval);
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value > this.now) this.now = new Date(this.value.valueOf());
        this.syncFlatPickrDate();
      }
    },
    localTimezone: {
      immediate: true,
      handler() {
        this.syncFlatPickrDate();
      }
    },
    timeline: {
      immediate: true,
      handler() {
        if (this.timeline === 'current_power') this.$emit('input', moment(this.now).tz(this.localTimezone).toDate());
      }
    }
  }
};
</script>

<style lang="scss">
.date-btns {
  color: $msi-orange;
  cursor: pointer;
}

.date-btns-disabled {
  color: rgb(156, 98, 21);
  cursor: default;
  user-select: none;
  pointer-events: none;
}

.date-btns-text {
  font-size: 0.75rem;
}

.date-icon {
  width: 5px;
  height: 10px;
}

.summary-date.flatpickr-input {
  text-align: center;
  width: 100px;
}

.summary-date.flatpickr-input:disabled {
  cursor: default;
}
</style>
