<template>
  <div>
    <h5>{{ clusterName }}</h5>
    <div v-for="[name, value] in fields" :key="`${cluster.id}-attr${name}`">
      <span><b>{{name}}:</b> {{value}}</span>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import { valueMagnitude, unitMagnitude } from './helpers';

export default {
  name: 'ClusterInfo',
  props: {
    cluster: {
      type: Object,
    },
    valueData: {
      type: Number,
      default: 0
    },
    valueType: {
      type: String
    }
  },
  computed: {
    getGroupByUuid: get('groups/getGroupByUuid'),
    clusterName() {
      const { groupUuid } = this.cluster;
      const group = this.getGroupByUuid(groupUuid);
      if (group) return group.name;
      return 'Ungrouped';
    },
    fields() {
      const { moduleUuids } = this.cluster;
      const fields = [];
      fields.push(['Number of Modules', moduleUuids.length]);
      if (this.valueType === 'energy') fields.push(['Average Energy', `${valueMagnitude(this.valueData)} ${unitMagnitude(this.valueData, 'Wh')}`]);
      else fields.push(['Average Power', `${valueMagnitude(this.valueData)} ${unitMagnitude(this.valueData, 'W')}`]);
      return fields;
    }
  }
};
</script>
