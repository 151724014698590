/* eslint-disable prefer-destructuring */
import moment from 'moment-timezone';
import capitalize from 'lodash/capitalize';
import round from 'lodash/round';

const units = {
  metric: {
    temp: '\xB0C',
    wind: 'm/s'
  },
  imperial: {
    temp: '\xB0F',
    wind: 'mph'
  }
};

const mapping = {
  '01': 'clear',
  '02': 'partly-cloudy',
  '03': 'cloudy',
  '04': 'cloudy',
  '09': 'rain',
  10: 'rain',
  11: 'rain',
  13: 'snow',
  50: 'fog'
};

const weather = {
  day: {
    clear: { background: 'clear-day.jpg', icon: 'sun' },
    rain: { background: 'rain-day.jpg', icon: 'cloud-showers-heavy' },
    snow: { background: 'snow-day.jpg', icon: 'snowflake' },
    sleet: { background: 'sleet-day.jpg', icon: 'cloud-showers-heavy' },
    wind: { background: 'wind-day.jpg', icon: 'wind' },
    fog: { background: 'fog-day.jpg', icon: 'smog' },
    cloudy: { background: 'cloudy-day.jpg', icon: 'cloud' },
    'partly-cloudy': { background: 'partly-cloudy-day.jpg', icon: 'cloud-sun' }
  },
  night: {
    clear: { background: 'clear-night.jpg', icon: 'moon' },
    rain: { background: 'rain-night.jpg', icon: 'cloud-showers-heavy' },
    snow: { background: 'snow-night.jpg', icon: 'snowflake' },
    sleet: { background: 'sleet-night.jpg', icon: 'cloud-showers-heavy' },
    wind: { background: 'wind-night.jpg', icon: 'wind' },
    fog: { background: 'fog-night.jpg', icon: 'smog' },
    cloudy: { background: 'cloudy-night.jpg', icon: 'cloud' },
    'partly-cloudy': { background: 'partly-cloudy-night.jpg', icon: 'cloud-moon' }
  }
};

class Forecast {
  constructor(forecast, timezone) {
    this.formatForecast(forecast, timezone);
  }

  formatForecast(forecast, timezone) {
    const formattedForecast = [];
    const unit = units[forecast.units];
    const { daily: dailyForecast } = forecast.forecast;

    for (let i = 0; i < dailyForecast.length - 1; i++) {
      const day = dailyForecast[i] || {};

      if (i === 0) {
        const current = forecast.current || {};
        const now = moment.utc(current.dt * 1000).tz(timezone);

        let icon;
        let background;
        if (current.weather && current.weather[0] && current.weather[0].icon) {
          const key = mapping[current.weather[0].icon.slice(0, 2)];

          if (!key) {
            icon = 'minus';
            background = weather.day.clear.background;
          } else if (current.sys && current.sys.sunset && now.isAfter(moment.utc(current.sys.sunset * 1000).tz(timezone))) {
            icon = weather.night[key].icon;
            background = weather.night[key].background;
          } else if (current.sys && current.sys.sunrise && now.isBefore(moment.utc(current.sys.sunrise * 1000).tz(timezone))) {
            icon = weather.night[key].icon;
            background = weather.night[key].background;
          } else {
            icon = weather.day[key].icon;
            background = weather.day[key].background;
          }
        } else {
          icon = 'minus';
          background = weather.day.clear.background;
        }

        const summary = current.weather && current.weather[0] ? capitalize(current.weather[0].description) : '';
        const date = now.format('[Today], MMM D, YYYY');
        const temperature = current.main && current.main.temp != null ? `${round(current.main.temp)} ${unit.temp}` : '-';
        const minTemperature = current.main && current.main.temp_min != null ? `${round(current.main.temp_min)} ${unit.temp}` : '-';
        const maxTemperature = current.main && current.main.temp_max != null ? `${round(current.main.temp_max)} ${unit.temp}` : '-';
        const windSpeed = current.wind && current.wind.speed != null ? `${round(current.wind.speed, 1)} ${unit.wind}` : '-';
        const cloudCover = current.clouds && current.clouds.all != null ? `${round(current.clouds.all, 1)}%` : '-';
        const precipProbability = day.pop != null ? `${round(day.pop * 100, 1)}%` : '-';

        formattedForecast.push({
          icon, background, summary, date, temperature, minTemperature, maxTemperature, windSpeed, cloudCover, precipProbability
        });
      } else {
        let icon;
        let background;
        if (day.weather && day.weather[0] && day.weather[0].icon) {
          const key = mapping[day.weather[0].icon.slice(0, 2)];

          if (!key) {
            icon = 'minus';
            background = weather.day.clear.background;
          } else {
            icon = weather.day[key].icon;
            background = weather.day[key].background;
          }
        } else {
          icon = 'minus';
          background = weather.day.clear.background;
        }

        const summary = day.weather && day.weather[0] ? capitalize(day.weather[0].description) : '';
        const date = moment.utc(day.dt * 1000).tz(timezone).format('dddd, MMM D, YYYY');
        const temperature = day.temp && day.temp.day != null ? `${round(day.temp.day)} ${unit.temp}` : '-';
        const minTemperature = day.temp && day.temp.min != null ? `${round(day.temp.min)} ${unit.temp}` : '-';
        const maxTemperature = day.temp && day.temp.max != null ? `${round(day.temp.max)} ${unit.temp}` : '-';
        const windSpeed = day.wind_speed != null ? `${round(day.wind_speed, 1)} ${unit.wind}` : '-';
        const cloudCover = day.clouds != null ? `${round(day.clouds, 1)}%` : '-';
        const precipProbability = day.pop != null ? `${round(day.pop * 100, 1)}%` : '-';

        formattedForecast.push({
          icon, background, summary, date, temperature, minTemperature, maxTemperature, windSpeed, cloudCover, precipProbability
        });
      }
    }

    this.forecast = formattedForecast;
  }

  getForecast() {
    return this.forecast;
  }
}

export default Forecast;
