/* eslint-disable new-cap */
/* eslint-disable no-undef */
import SunCalc from 'suncalc';
import moment from 'moment-timezone';
import potpack from 'potpack';

function toRadians(angle) {
  return Math.PI * angle / 180.0;
}

function toDegrees(angle) {
  return angle * (180.0 / Math.PI);
}

function computeOffset(from, distance, heading) {
  const EARTH_RADIUS = 6371009.0;

  distance /= EARTH_RADIUS;
  heading = toRadians(heading);

  const fromLat = toRadians(from.lat);
  const fromLng = toRadians(from.lng);

  const cosDistance = Math.cos(distance);
  const sinDistance = Math.sin(distance);

  const sinFromLat = Math.sin(fromLat);
  const cosFromLat = Math.cos(fromLat);

  const sinLat = cosDistance * sinFromLat + sinDistance * cosFromLat * Math.cos(heading);

  const dLng = Math.atan2(sinDistance * cosFromLat * Math.sin(heading), cosDistance - sinFromLat * sinLat);
  return new L.latLng(toDegrees(Math.asin(sinLat)), toDegrees(fromLng + dLng));
}

export function computeModuleBounds(center, length, width) {
  const moduleLength = length;
  const moduleWidth = width;

  const centerLatLng = L.latLng(center.lat, center.lng);
  const topCenter = computeOffset(centerLatLng, moduleLength / 2, 0.0);
  const topLeft = computeOffset(topCenter, 0 - moduleWidth / 2, 90.0);
  const bottomCenter = computeOffset(centerLatLng, 0 - moduleLength / 2, 0.0);
  const botttomRight = computeOffset(bottomCenter, moduleWidth / 2, 90.0);
  return L.latLngBounds(topLeft, botttomRight);
}

export function generateCoords(solarModules) {
  if (!Array.isArray(solarModules)) {
    throw new Error('Parameter is not an array');
  }

  const yOffset = 0.2;
  const xOffset = 0.2;

  let specs = solarModules.map(({ moduleDesign, uuid }) => ({
    w: moduleDesign.width + xOffset,
    h: moduleDesign.length + yOffset,
    uuid
  }));
  const { w: stringWidth, h: stringHeight } = potpack(specs);
  specs = specs.map((spec) => {
    return {
      ...spec,
      x: spec.x + spec.w / 2,
      y: spec.y + spec.h / 2,
    };
  });

  const newSolarModules = solarModules.filter(({ coords }) => !coords).map((solarModule, index) => ({ index, ...solarModule }));

  newSolarModules.forEach((solarModule) => {
    const fit = specs.find(spec => spec.uuid === solarModule.uuid);
    solarModule.coords = { lng: fit.x, lat: fit.y };
    solarModule.angle = 0.0;
  });

  return { solarModules: newSolarModules, stringWidth, stringHeight };
}

export function layOutStrings(strings) {
  const mappedModuleStrings = Object.entries(strings).reduce((acc, [stringUuid, string]) => {
    acc[stringUuid] = generateCoords(string);
    return acc;
  }, {});

  const yOffset = 0.5;
  const xOffset = 0.5;

  const specs = Object.entries(mappedModuleStrings).map(([uuid, { stringWidth, stringHeight }]) => ({
    w: stringWidth + xOffset,
    h: stringHeight + yOffset,
    uuid
  }));

  potpack(specs);

  Object.entries(mappedModuleStrings).forEach(([uuid, string]) => {
    const fit = specs.find(s => s.uuid === uuid);
    string.solarModules = string.solarModules.map((solarModule) => {
      let pos = L.latLng(0.0, 0.0);
      pos = computeOffset(pos, -(fit.y + solarModule.coords.lat), 0.0);
      pos = computeOffset(pos, fit.x + solarModule.coords.lng, 90.0);
      return { ...solarModule, coords: { lat: pos.lat, lng: pos.lng } };
    });
  });

  return Object.entries(mappedModuleStrings).reduce((acc, [uuid, string]) => (
    { ...acc, [uuid]: string.solarModules }
  ), {});
}

export function computeMapBounds(mapSolarModuleStrings) {
  let mapBounds = null;

  Object.keys(mapSolarModuleStrings).forEach((stringUuid) => {
    let mapSolarModuleStringBounds = null;

    mapSolarModuleStrings[stringUuid].forEach((solarModule) => {
      const solarModuleBounds = computeModuleBounds(solarModule.coords, solarModule.moduleDesign.length, solarModule.moduleDesign.width);

      if (mapSolarModuleStringBounds === null) {
        mapSolarModuleStringBounds = L.latLngBounds(solarModuleBounds.getSouthWest(), solarModuleBounds.getNorthEast());
      } else {
        mapSolarModuleStringBounds.extend(solarModuleBounds);
      }
    });

    if (mapBounds === null) {
      mapBounds = L.latLngBounds(mapSolarModuleStringBounds.getSouthWest(), mapSolarModuleStringBounds.getNorthEast());
    } else {
      mapBounds.extend(mapSolarModuleStringBounds);
    }
  });

  return mapBounds;
}

export function valueMagnitude(panelValueData, decimals = 1) {
  if (!panelValueData) return 0;
  if (panelValueData / 1000000000 >= 1) return (panelValueData / 1000000000).toFixed(decimals);
  if (panelValueData / 1000000 >= 1) return (panelValueData / 1000000).toFixed(decimals);
  if (panelValueData / 1000 >= 1) return (panelValueData / 1000).toFixed(decimals);
  return panelValueData.toFixed(decimals);
}

export function unitMagnitude(panelValueData, unit) {
  let magnitude = '';
  if (panelValueData / 1000000000 >= 1) magnitude = 'G';
  if (panelValueData / 1000000 >= 1) magnitude = 'M';
  if (panelValueData / 1000 >= 1) magnitude = 'k';
  return `${magnitude}${unit}`;
}

export function computePredictedEnergyGenerationPerModuleType(modules, energyData, startDate, endDate, coords, timezone) {
  if (!modules.length) return {};
  const from = moment.parseZone(startDate).startOf('day').add(12, 'h');
  const to = moment.parseZone(endDate).endOf('day');
  const now = moment().tz(timezone);

  const times = [];
  while (from.isSameOrBefore(to)) {
    times.push(SunCalc.getTimes(from.toDate(), coords.lat, coords.lng));
    from.add(1, 'd');
  }
  const totalTime = times.reduce((acc, time) => {
    if (!time.sunrise || !time.sunset) return acc;
    const end = moment(now).isBetween(moment(time.sunrise), moment(time.sunset)) ? now : time.sunset;
    // Time in hours
    return acc + (Math.abs(end - time.sunrise) / (1000 * 60 * 60));
  }, 0);

  const types = modules.reduce((acc, solarModule) => {
    const stringTypes = { ...acc };
    const { name, nameplateStcPower } = solarModule.moduleDesign;
    if (!name) return stringTypes;
    const moduleTypeLimits = stringTypes[name] || { _max: energyData[solarModule.uuid], max: 0, min: 0 };

    moduleTypeLimits._max = Math.max(moduleTypeLimits._max || 0, energyData[solarModule.uuid] || 0);
    if (nameplateStcPower && !moduleTypeLimits.npr) {
      moduleTypeLimits.npr = nameplateStcPower;
      moduleTypeLimits.max = Math.floor(nameplateStcPower * totalTime);
      moduleTypeLimits.nprMax = Math.floor(nameplateStcPower * totalTime);
    }
    stringTypes[name] = moduleTypeLimits;
    return stringTypes;
  }, {});

  return types;
}
