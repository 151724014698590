<template>
  <g :id="solarModuleId" class="module" ref="module">
    <rect class="panel" width="100" height="200" fill="#000a7a" stroke="#4f4f4f" stroke-width="6" />
    <line class="panel-grid" x1="25" y1="0" x2="25" y2="200" stroke="#4f4f4f" stroke-width="4" />
    <line class="panel-grid" x1="50" y1="0" x2="50" y2="200" stroke="#4f4f4f" stroke-width="4" />
    <line class="panel-grid" x1="75" y1="0" x2="75" y2="200" stroke="#4f4f4f" stroke-width="4" />
    <line class="panel-grid" x1="0" y1="50" x2="100" y2="50" stroke="#4f4f4f" stroke-width="4" />
    <line class="panel-grid" x1="0" y1="100" x2="100" y2="100" stroke="#4f4f4f" stroke-width="4" />
    <line class="panel-grid" x1="0" y1="150" x2="100" y2="150" stroke="#4f4f4f" stroke-width="4" />
    <text x="50" y="100" dy="-20" fill="#ffffff" text-anchor="middle" dominant-baseline="middle" font-size="35"
      font-family="Arial, Helvetica, sans-serif">
      {{ panelValueData | round }}
    </text>
    <text x="50" y="100" dy="20" fill="#ffffff" text-anchor="middle" dominant-baseline="middle" font-size="35"
      font-family="Arial, Helvetica, sans-serif">
      {{ unit }}
    </text>
    <foreignObject>
      <div v-if="ready" xmlns="http://www.w3.org/1999/xhtml"><slot></slot></div>
    </foreignObject>
  </g>
</template>

<script>
/* global L */
import { findRealParent } from 'vue2-leaflet';
import chroma from 'chroma-js';

import './L.CustomSVG';
import { computeModuleBounds, unitMagnitude } from './helpers';

export default {
  name: 'SolarModule',
  props: {
    solarModule: {
      type: Object,
      required: true
    },
    angle: {
      type: Number,
      default: 0
    },
    selected: {
      type: Boolean,
      default: false
    },
    highlighted: {
      type: Boolean,
      default: false
    },
    panelValueData: {
      type: Number,
      default: 0
    },
    panelValueType: {
      type: String
    },
    heatmap: {
      type: Boolean
    },
    gradientColors: {
      type: Array,
      default: () => [],
    },
    maxGradientValue: {
      type: Number
    },
    minGradientValue: {
      type: Number
    },
    selectedSolarModuleType: {
      type: String,
    },
    maxValue: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      solarModuleId: `m_${this.solarModule.uuid}`,
      ready: false
    };
  },
  computed: {
    solarModuleViewState() {
      if (this.highlighted) return 0;
      if (this.selected) return 1;
      return 2;
    },
    unit() {
      const { panelValueData, panelValueType } = this;
      const unit = panelValueType === 'power' ? 'W' : 'Wh';
      return unitMagnitude(panelValueData, unit);
    },
    showHeatmap() {
      if (!this.heatmap) return false;
      if (!this.solarModule.moduleDesign || !this.solarModule.moduleDesign.nameplateStcPower) return false;
      const { name: typeName } = this.solarModule.moduleDesign;
      return this.selectedSolarModuleType === typeName;
    }
  },
  methods: {
    updateSolarModuleView() {
      if (!this.mapObject) return;

      if (this.solarModuleViewState === 0) this.mapObject.setSVGElementStyle('panel', 'fill: #EC8E12');
      else if (this.solarModuleViewState === 1) this.mapObject.setSVGElementStyle('panel', 'fill: #099FEB');
      else if (this.showHeatmap) {
        const scale = chroma.scale(this.gradientColors).domain([this.minGradientValue, this.maxGradientValue]);
        let ratio = this.panelValueData;
        if (this.panelValueData > this.maxGradientValue) {
          ratio = this.maxGradientValue;
        } else if (this.panelValueData < this.minGradientValue) {
          ratio = this.minGradientValue;
        }
        this.mapObject.setSVGElementStyle('panel', `fill: ${scale(ratio).hex()}`);
      } else {
        this.mapObject.setSVGElementStyle('panel', 'fill: #000a7a');
      }
    },
    unbindTooltip() {
      const tooltip = this.mapObject ? this.mapObject.getTooltip() : null;
      if (tooltip) {
        this.mapObject.unbindTooltip();
      }
    },
    setup() {
      if (this.$el.parentNode) this.$el.parentNode.removeChild(this.$el);

      this.mapObject = L.customSVG(this.$el, computeModuleBounds({ lat: this.solarModule.coords.lat, lng: this.solarModule.coords.lng },
        this.solarModule.moduleDesign.length, this.solarModule.moduleDesign.width), { width: 100, height: 200 }, { angle: this.angle });

      this.mapObject.on('click', (e) => {
        L.DomEvent.stopPropagation(e);
        this.$emit('click', e, this.solarModule);
      });

      this.mapObject.on('mouseover', () => this.$emit('mouseover', this.solarModule));
      this.mapObject.on('mouseout', () => this.$emit('mouseout', this.solarModule));
      this.mapObject.on('contextmenu', e => this.$emit('contextmenu', e, this.solarModule));

      this.parentContainer = findRealParent(this.$parent);
      this.parentContainer.addLayer(this, false);
      this.ready = true;
      this.updateSolarModuleView();
    }
  },
  mounted() {
    this.setup();
  },
  beforeDestroy() {
    this.parentContainer.removeLayer(this);
  },
  watch: {
    solarModule() {
      this.setup();
    },
    solarModuleViewState() {
      this.updateSolarModuleView();
    },
    heatmap() {
      this.updateSolarModuleView();
    },
    panelValueData() {
      this.updateSolarModuleView();
    },
    maxGradientValue() {
      this.updateSolarModuleView();
    },
    minGradientValue() {
      this.updateSolarModuleView();
    },
    showHeatmap() {
      this.updateSolarModuleView();
    }
  },
  filters: {
    round: (value) => {
      if (!value) return 0;
      if (value / 1000000000 >= 1) return (value / 1000000000).toFixed(1);
      if (value / 1000000 >= 1) return (value / 1000000).toFixed(1);
      if (value / 1000 >= 1) return (value / 1000).toFixed(1);
      return value.toFixed(1);
    }
  }
};
</script>

<style>
.leaflet-overlay-pane path,
.leaflet-marker-icon,
.leaflet-image-layer,
.module {
  cursor: pointer
}

.module {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
