/* global L */

L.CustomSVGRenderer = L.Renderer.extend({

  onAdd() {
    if (!this._container) {
      this._initContainer();
      if (this._zoomAnimated) {
        L.DomUtil.addClass(this._container, 'leaflet-zoom-animated');
      }
    }

    if (!this._map.getPanes.customSVGPane) {
      this._map.createPane('customSVGPane');
    }

    this._map.getPane('customSVGPane').appendChild(this._container);
    this._update();
  },

  getEvents() {
    const events = L.Renderer.prototype.getEvents.call(this);
    events.zoomstart = this._onZoomStart;
    events.move = this._update;
    return events;
  },

  _initContainer() {
    this._container = L.CustomSVGRenderer.create('svg');
    this._rootGroup = L.CustomSVGRenderer.create('g');
    this._container.appendChild(this._rootGroup);
  },

  _onZoomStart() {
    this._update();
  },

  _update() {
    if (this._map._animatingZoom && this._bounds) return;

    L.Renderer.prototype._update.call(this);

    const b = this._bounds;
    const size = b.getSize();
    const container = this._container;

    if (!this._svgSize || !this._svgSize.equals(size)) {
      this._svgSize = size;
      container.setAttribute('width', size.x);
      container.setAttribute('height', size.y);
    }

    L.DomUtil.setPosition(container, b.min);
    container.setAttribute('viewBox', [b.min.x, b.min.y, size.x, size.y].join(' '));
    this.fire('update');
  },

  _destroyContainer() {
    L.DomUtil.remove(this._container);
    L.DomEvent.off(this._container);
    delete this._container;
    delete this._rootGroup;
    delete this._svgSize;
  },

  _addSVGElement(layer) {
    this._rootGroup.appendChild(layer._svgElement);
    layer.addInteractiveTarget(layer._svgElement);
  },

  _removeSVGElement(layer) {
    L.DomUtil.remove(layer._svgElement);
    layer.removeInteractiveTarget(layer._svgElement);
  },

  _bringToFront(layer) {
    L.DomUtil.toFront(layer._svgElement);
  },

  _bringToBack(layer) {
    L.DomUtil.toBack(layer._svgElement);
  }
});

L.extend(L.CustomSVGRenderer, {
  create(name) {
    return document.createElementNS('http://www.w3.org/2000/svg', name);
  }
});

L.customSVGRenderer = (options) => {
  return new L.CustomSVGRenderer(options);
};

L.Map.include({
  getCustomSVGRenderer() {
    let renderer = this._paneRenderers.customSVGPane;
    if (!renderer) {
      this._paneRenderers.customSVGPane = L.customSVGRenderer();
      renderer = this._paneRenderers.customSVGPane;
    }

    if (!this.hasLayer(renderer)) {
      this.addLayer(renderer);
    }
    return renderer;
  }
});
