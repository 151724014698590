<template>
  <div>
    <div class="pb-2">
      <h6>Settings for {{ selectedSite.name }}</h6>
    </div>

    <b-form-group
      label="Default Metrics"
      :description="`
        Allows you to set site-level default metrics.
        Groups and modules inherit these default metrics unless they override them.
      `">
      <default-metrics v-model="defaultMetrics" :metrics="metrics" />
    </b-form-group>

    <div class="d-flex justify-content-end">
      <msi-spinner :size="30" v-if="loading" />
      <b-button variant="primary" class="ml-2" size="sm" @click="handleSave" :disabled="loading">Save</b-button>
    </div>

    <b-modal title="Update Site Settings?" hide-footer v-model="updateSiteWarning">
      <div>
        You are about to update the site settings and will lose all your plotted graphs.
        Are you sure you want to update the settings?
      </div>
      <div class="d-flex justify-content-end pt-2">
        <b-button variant="primary" size="sm" class="mr-2" @click="handleUpdateSite">Update Settings</b-button>
        <b-button variant="secondary" size="sm" @click="cancelUpdateSite">Cancel</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BFormGroup, BButton, BModal } from 'bootstrap-vue';
import { get } from 'vuex-pathify';
import MsiSpinner from './MsiSpinner.vue';

import DefaultMetrics from './SiteSettings/DefaultMetrics.vue';

export default {
  name: 'SiteSettings',
  components: {
    BFormGroup,
    BButton,
    BModal,
    MsiSpinner,
    DefaultMetrics
  },
  data() {
    return {
      defaultMetrics: {},
      loading: false,
      updateSiteWarning: false
    };
  },
  computed: {
    selectedSite: get('sites/selectedSite'),
    selectedSiteActiveAnalysis: get('sites/selectedSiteActiveAnalysis'),
    getSiteMetrics: get('metrics/getSiteMetrics'),
    metrics() {
      if (!this.selectedSite) return [];
      return this.getSiteMetrics(this.selectedSite.id);
    }
  },
  methods: {
    handleSave() {
      if (this.selectedSiteActiveAnalysis) {
        this.updateSiteWarning = true;
      } else {
        this.handleUpdateSite();
      }
    },
    async handleUpdateSite() {
      this.updateSiteWarning = false;

      try {
        this.loading = true;
        const body = { defaultMetrics: this.defaultMetrics };
        await this.$daqApi.put(`/sites/${this.selectedSite.id}`, { body });
        await this.$store.set('sites/sites');
        this.$toastSuccess('Successful', 'The site settings have been updated successfully.');
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.loading = false;
      }
    },
    cancelUpdateSite() {
      this.updateSiteWarning = false;
    }
  },
  watch: {
    selectedSite: {
      immediate: true,
      handler() {
        if (this.selectedSite && this.selectedSite.ownDefaultMetrics) this.defaultMetrics = this.selectedSite.ownDefaultMetrics;
      }
    }
  }
};
</script>
