<template>
  <svg xmlns="http://www.w3.org/2000/svg" >
    <g v-for="(solarModule, index) in solarModules" :key="`Module${solarModule.uuid}-${!!solarModules.coords}`">
      <solar-module

        :solarModule="solarModule"
        :selected="selected[index]"
        :highlighted="highlighted[index]"
        :panelValueData="panelValues.data[solarModule.uuid]"
        :panelValueType="panelValues.type"
        :heatmap="heatmap"
        :gradientColors="gradientColors"
        :maxValue="maxValue"
        :maxGradientValue="gradientTypes[solarModule.moduleDesign.name] ? gradientTypes[solarModule.moduleDesign.name].max : 0"
        :minGradientValue="gradientTypes[solarModule.moduleDesign.name] ? gradientTypes[solarModule.moduleDesign.name].min : 0"
        :selectedSolarModuleType="selectedSolarModuleType"
        @click="handleClick"
        @mouseover="handleMouseOver"
        @mouseout="handleMouseOut"
        @contextmenu="handleContextMenu">

        <l-tooltip v-if="infoActive">
          <solar-module-info :solarModule="solarModule" />
        </l-tooltip>
      </solar-module>
    </g>
  </svg>
</template>

<script>
import { LTooltip } from 'vue2-leaflet';
import SolarModule from './SolarModule.vue';
import SolarModuleInfo from './SolarModuleInfo.vue';

export default {
  name: 'SolarModuleString',
  components: {
    SolarModule,
    SolarModuleInfo,
    LTooltip
  },
  props: {
    solarModules: {
      type: Array
    },
    angle: {
      type: Number,
      default: 0
    },
    selectedSolarModules: {
      type: Object,
      default: null
    },
    infoActive: {
      type: Boolean,
      default: false
    },
    panning: {
      type: Boolean,
      default: false
    },
    panelValues: {
      type: Object
    },
    heatmap: {
      type: Boolean
    },
    gradientColors: {
      type: Array,
      default: () => [],
    },
    gradientTypes: {
      type: Object
    },
    selectedSolarModuleType: {
      type: String,
    },
    maxValue: {
      type: Number
    }
  },
  data() {
    return {
      currentMouseOverSolarModule: null
    };
  },
  computed: {
    selected() {
      const selected = [];

      this.solarModules.forEach((solarModule) => {
        if (this.selectedSolarModules && this.selectedSolarModules[solarModule.uuid]) selected.push(true);
        else selected.push(false);
      });

      return selected;
    },
    highlighted() {
      const highlighted = [];

      if (this.panning) return highlighted;

      this.solarModules.forEach((solarModule) => {
        if (solarModule.uuid === this.currentMouseOverSolarModule) highlighted.push(true);
        else highlighted.push(false);
      });

      return highlighted;
    }
  },
  methods: {
    handleClick(event, selectedSolarModule) {
      let newSelectedSolarModules = {};
      Object.assign(newSelectedSolarModules, this.selectedSolarModules);
      let maintainStringStates = false;

      if (event.originalEvent.ctrlKey || event.originalEvent.metaKey) {
        maintainStringStates = true;

        if (newSelectedSolarModules[selectedSolarModule.uuid]) {
          delete newSelectedSolarModules[selectedSolarModule.uuid];
        } else {
          newSelectedSolarModules[selectedSolarModule.uuid] = selectedSolarModule;
        }
      } else if (newSelectedSolarModules[selectedSolarModule.uuid]) {
        maintainStringStates = true;
        delete newSelectedSolarModules[selectedSolarModule.uuid];
      } else {
        newSelectedSolarModules = {};
        newSelectedSolarModules[selectedSolarModule.uuid] = selectedSolarModule;
      }

      this.$emit('click', maintainStringStates, newSelectedSolarModules);
    },
    handleMouseOver(solarModule) {
      this.currentMouseOverSolarModule = solarModule.uuid;
      this.$emit('hoveredSolarModule', solarModule);
    },
    handleMouseOut() {
      this.currentMouseOverSolarModule = null;
      this.$emit('hoveredSolarModule', null);
    },
    handleContextMenu(event, solarModule) {
      this.$emit('contextmenu', event, solarModule);
    }
  },
  mounted(){
    if (this.$el.parentNode) this.$el.parentNode.removeChild(this.$el);
  }
};
</script>
