<template>
  <b-container fluid class="site-summary-container d-flex flex-column m-0 px-2 px-md-3 pt-2 pt-md-3">
    <b-row no-gutters class="flex-grow-1 w-100 pb-2 pb-md-3 min-height-fit">
      <b-col md="6" lg="8" class="d-flex flex-column pt-2 pt-md-0 min-height-fit" order="2" order-md="1">
        <b-card no-body class="flex-grow-1 d-flex flex-column map-card m-0 overflow-hidden">
          <b-card-body class="flex-grow-1 d-flex flex-column p-0">

            <site-map
              class="flex-grow-1"
              :solarModuleStrings="solarModuleStrings"
              :mapped="mapped"
              :displayId="displayId"
              :panelValues="panelValues"
              :from="from"
              :to="to"
              :loading="loadingSites || requestInProgressMap"
              @infoActiveChange="infoActive = $event"
              @selectedSolarModules="handleSelectedSolarModules"
              @hoveredSolarModule="handleHoveredSolarModule">
              <template v-slot:mapped-selector>
                <div ref="mappedSelector">
                  <div v-if="solarModuleViewChoices.length">
                    <b-form-radio-group
                      v-model="mapped"
                      :options="mappedOptions"
                      buttons
                      class="summary-radio mb-2"
                      :disabled="requestInProgressMap"
                      v-if="showMappedToggle">
                    </b-form-radio-group>
                    <multiselect
                      v-model="selectedSolarModuleView"
                      :options="solarModuleViewChoices"
                      selectLabel=""
                      deselectLabel=""
                      placeholder="Group Filter"
                      searchable
                      multiple
                      :limit="1"
                      :disabled="requestInProgressMap"
                      :closeOnSelect="false"
                      track-by="uuid"
                      label="name"
                      class="view-multiselect">
                      <template slot="beforeList" v-if="selectedSolarModuleView.length">
                        <li class="view-multiselect-show-all multiselect__element" @click="selectedSolarModuleView = []">
                          <span class="multiselect__option"><span>Show All</span></span>
                        </li>
                      </template>
                    </multiselect>
                  </div>
                </div>
              </template>
            </site-map>
          </b-card-body>

          <b-card-footer class="px-2">
            <b-row no-gutters class="m-0">
              <b-col xs="12" sm="12" md="12" lg="6">
                <b-form-radio-group
                  v-model="timeline"
                  :options="timelineOptions"
                  buttons
                  class="summary-radio"
                  size="sm"
                  :disabled="disabledControls">
                </b-form-radio-group>
              </b-col>

              <b-col xs="12" sm="12" md="12" lg="6">
                <date-controls
                  v-model="date"
                  :timezone="selectedSite ? selectedSite.timezone : null"
                  :timeline="timeline"
                  :disabled="disabledControls" />
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </b-col>

      <b-col md="6" lg="4" class="d-flex flex-column pl-0 pl-md-2 min-height-fit" order="1" order-md="2">
        <b-card no-body class="flex-grow-0 flex-shrink-0 m-0 mb-2 overflow-hidden">
          <site-status :site="selectedSite" :siteStatus="siteStatus" :currentPower="currentTotalPower" :loading="requestInProgressSiteStatus" />
        </b-card>

        <b-card no-body class="flex-grow-0 flex-shrink-0 m-0 mb-2 overflow-hidden">
          <weather-carousel :site="selectedSite" />
        </b-card>

        <b-card no-body class="power-chart-card flex-grow-1 flex-shrink-1 m-0 p-2 d-flex flex-column">
          <high-charts-responsive class="highcharts-container" :options="energyGraph"
            ref="energyGraph" key="energyGraph" v-if="timeline !== 'current_power'" />
          <current-power-carousel :selectedSolarModules="selectedSolarModules" :hoveredSolarModule="hoveredSolarModule"
            :currentPower="currentPower" :infoActive="infoActive"
            v-if="timeline === 'current_power'" />
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
/* eslint-disable import/no-named-default */
import * as L from 'leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import { get } from 'vuex-pathify';
import Multiselect from 'vue-multiselect';
import moment from 'moment-timezone';
import { BContainer, BRow, BCol, BCard, BCardBody, BFormRadioGroup, BCardFooter } from 'bootstrap-vue';

import SiteMap from '../components/leaflet/SiteMap.vue';
import DateControls from '../components/leaflet/DateControls.vue';
import { default as HighChartsResponsive, timeDependentButtons } from '../components/HighChartsResponsive.vue';
import WeatherCarousel from '../components/WeatherCarousel.vue';
import SiteStatus from '../components/SiteStatus.vue';
import CurrentPowerCarousel from '../components/CurrentPowerCarousel.vue';
import { extractEnergyData, parseEnergy } from '../components/graphs/helpers';
import { naturalCompare } from '../helpers/helpers';

const energyPeriodMapper = { day: 'daily', week: 'weekly', month: 'monthly', year: 'yearly' };

function extractTotalModuleEnergy(data) {
  return data.reduce((acc, cur) => {
    if (acc[cur.moduleUuid]) acc[cur.moduleUuid] += cur.energy;
    else acc[cur.moduleUuid] = cur.energy;
    return acc;
  }, {});
}

let currentPowerInterval = null;

export default {
  name: 'SiteSummary',
  components: {
    SiteMap,
    DateControls,
    HighChartsResponsive,
    WeatherCarousel,
    SiteStatus,
    CurrentPowerCarousel,
    Multiselect,
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormRadioGroup,
    BCardFooter
  },
  data() {
    return {
      selectedSolarModules: [],
      hoveredSolarModule: null,
      timelineOptions: [
        { text: this.$i18n.t('site_map.current_power'), value: 'current_power' },
        { text: this.$i18n.t('site_map.day'), value: 'day' },
        { text: this.$i18n.t('site_map.week'), value: 'week' },
        { text: this.$i18n.t('site_map.month'), value: 'month' },
        { text: this.$i18n.t('site_map.year'), value: 'year' }
      ],
      timeline: 'day',
      date: null,
      selectedSolarModuleView: [],
      siteEnergyData: { map: {}, graph: {} },
      siteStatusData: { today: [], month: [], totalEnergy: [] },
      currentPower: {},
      requestInProgressSiteStatus: false,
      requestInProgressMap: true,
      requestInProgressGraph: true,
      infoActive: false,
      isComponentActive: true,
      mapped: false,
      mappedOptions: [{ value: true, text: 'Mapped' }, { value: false, text: 'Unmapped' }],
      energyGraph: {
        series: [],
        title: { text: 'Energy' },
        chart: { type: 'column' },
        plotOptions: { column: { stacking: 'normal' } },
        xAxis: { type: 'datetime', title: { text: 'Time' }, showEmpty: false },
        yAxis: {
          labels: {
            align: 'left',
            x: 0,
            y: -2,
            formatter: function formatter() {
              const energy = this.value;
              return parseEnergy(energy, 2);
            }
          },
          title: { text: 'Energy' },
          showEmpty: false
        },
        legend: { enabled: false },
        responsive: { rules: [{ condition: { maxWidth: 420 }, chartOptions: { yAxis: { title: { text: '' } } } }] },
        tooltip: {
          formatter: function formatter(tooltip) {
            const energy = parseEnergy(this.point.y, 2);
            const date = tooltip.defaultFormatter.call(this, tooltip)[0];
            return `${date}<span style="color: ${this.color}">\u25CF</span> ${this.series.name}<br/>
            <b>Energy: </b><span style="color: #37872d; font-weight: bold;">${energy}</span>`;
          }
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: timeDependentButtons
            }
          },
          filename: 'energy'
        }
      }
    };
  },
  computed: {
    selectedSite: get('sites/selectedSite'),
    getSiteSolarModules: get('solarmodules/getSiteSolarModules'),
    getSolarModuleByUuid: get('solarmodules/getSolarModuleByUuid'),
    getGroupByUuid: get('groups/getGroupByUuid'),
    loadingSites: get('sites/loadingSites'),
    getGatewayByUuid: get('gateways/getGatewayByUuid'),
    getGatewayStatus: get('gateways/getGatewayStatus'),
    getSiteLinks: get('daqLinks/getSiteLinks'),
    userIdle: get('userIdle'),
    solarModulesInitialized: get('solarmodules/initialized'),
    siteLinks() {
      if (!this.selectedSite) return [];
      return this.getSiteLinks(this.selectedSite.id);
    },
    siteModulesInitialized() {
      return { siteId: this.selectedSite != null ? this.selectedSite.id : null, initialized: this.solarModulesInitialized };
    },
    solarModules() {
      if (!this.selectedSite) return [];
      return this.getSiteSolarModules(this.selectedSite.id);
    },
    mappedModules() {
      return this.solarModules.filter(({ coords }) => coords);
    },
    unmappedModules() {
      return this.solarModules.filter(({ coords }) => !coords);
    },
    showMappedToggle() {
      return this.mappedModules.length && this.unmappedModules.length;
    },
    shownSolarModules() {
      let modules;
      if (this.mapped) {
        modules = this.mappedModules;
      } else {
        modules = this.unmappedModules;
      }

      if (!this.solarModuleViewIds.length) return modules;
      return modules.filter(({ groupUuid }) => this.solarModuleViewIds.includes(groupUuid));
    },
    solarModuleStrings() {
      if (!this.selectedSite) return null;
      if (!this.solarModuleViewIds) return null;

      const siteSolarModules = this.shownSolarModules;

      const solarModuleStrings = {};
      siteSolarModules.forEach((solarModule) => {
        if (solarModuleStrings[solarModule.stringUuid]) {
          solarModuleStrings[solarModule.stringUuid].push(solarModule);
        } else {
          solarModuleStrings[solarModule.stringUuid] = [solarModule];
        }
      });

      return solarModuleStrings;
    },
    solarModuleViewChoices() {
      const choices = [];
      if (!this.selectedSite) return choices;
      let modules;
      if (this.mapped && this.mappedModules.length) {
        modules = this.mappedModules;
      } else {
        modules = this.unmappedModules;
      }

      let ungrouped = false;
      modules.forEach(({ groupUuid }) => {
        const group = this.getGroupByUuid(groupUuid);
        if (!group) ungrouped = true;
        if (group && !choices.some(({ uuid }) => uuid === groupUuid)) {
          choices.push({ name: group.name, uuid: groupUuid, mapped: true });
        }
      });
      if (ungrouped) choices.push({ name: 'Ungrouped', uuid: null, mapped: true });

      choices.sort((a, b) => naturalCompare(a.name, b.name));
      return choices;
    },
    solarModuleViewIds() {
      if (!this.selectedSolarModuleView) return [];
      return this.selectedSolarModuleView.map(({ uuid }) => uuid);
    },
    displayId() {
      if (!this.selectedSite) return null;
      return `${this.selectedSite.id}_${this.mapped}`;
    },
    from() {
      if (!this.date || !this.selectedSite) return null;
      if (this.timeline === 'current_power') return moment().tz(this.selectedSite.timezone).startOf('day').toISOString(true);
      let { timeline } = this;
      if (this.timeline === 'week') timeline = 'isoWeek';
      return moment(this.date).tz(this.selectedSite.timezone).startOf(timeline).toISOString(true);
    },
    to() {
      if (!this.date || !this.selectedSite) return null;
      if (this.timeline === 'current_power') return moment().tz(this.selectedSite.timezone).endOf('day').toISOString(true);
      let { timeline } = this;
      if (timeline === 'week') timeline = 'isoWeek';
      return moment(this.date).tz(this.selectedSite.timezone).endOf(timeline).toISOString(true);
    },
    disabledControls() {
      return this.requestInProgressMap || this.requestInProgressGraph;
    },
    panelValues() {
      if (this.timeline === 'current_power') return { type: 'power', data: this.currentPower };
      return { type: 'energy', data: this.siteEnergyData.map };
    },
    siteStatus() {
      let modules;
      if (this.mapped) modules = this.mappedModules.map(({ uuid }) => uuid);
      else modules = this.unmappedModules.map(({ uuid }) => uuid);

      const todayEnergy = this.siteStatusData.today.reduce((acc, cur) => (modules.includes(cur.moduleUuid) ? acc + cur.energy : acc), 0);
      const monthEnergy = this.siteStatusData.month.reduce((acc, cur) => (modules.includes(cur.moduleUuid) ? acc + cur.energy : acc), 0);
      const totalEnergy = this.siteStatusData.totalEnergy.reduce((acc, cur) => (modules.includes(cur.moduleUuid) ? acc + cur.energy : acc), 0);
      return { todayEnergy, monthEnergy, totalEnergy };
    },
    currentTotalPower() {
      let modules;
      if (this.mapped) modules = this.mappedModules.map(({ uuid }) => uuid);
      else modules = this.unmappedModules.map(({ uuid }) => uuid);
      return Object.keys(this.currentPower).reduce((acc, cur) => (modules.includes(cur) ? acc + this.currentPower[cur] : acc), 0);
    },
    gatewayStatus() {
      return this.siteLinks.reduce((acc, cur) => {
        if (!acc[cur.gateway.uuid]) {
          const gateway = this.getGatewayByUuid(cur.gateway.uuid);
          if (gateway) acc[cur.gateway.uuid] = this.getGatewayStatus(gateway.code);
          else acc[cur.gateway.uuid] = 'PENDING';
        }

        return acc;
      }, {});
    }
  },
  methods: {
    handleSelectedSolarModules(selectedSolarModules) {
      if (selectedSolarModules) this.selectedSolarModules = Object.values(selectedSolarModules);
      else this.selectedSolarModules = [];
    },
    handleHoveredSolarModule(solarModule) {
      if (this.timeline !== 'current_power') return;
      this.hoveredSolarModule = solarModule;
    },
    async getSiteEnergyData() {
      if (!this.selectedSite || this.timeline === 'current_power' || !this.from || !this.to) return;

      try {
        if (this.$refs.energyGraph) this.$refs.energyGraph.chart.showLoading();
        this.requestInProgressMap = true;
        this.requestInProgressGraph = true;

        const { from, to } = this;
        const period = energyPeriodMapper[this.timeline];
        const [mapEnergy, graphEnergy] = await Promise.all([
          this.$daqApi.get(`/sites/${this.selectedSite.id}/energy`, { query: { from, to, period } }),
          this.$daqApi.get(`/sites/${this.selectedSite.id}/energy`, { query: { from, to } })
        ]);

        this.siteEnergyData = { map: extractTotalModuleEnergy(mapEnergy), graph: extractEnergyData(graphEnergy) };
        this.populateEnergyGraph();
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        if (this.$refs.energyGraph) this.$refs.energyGraph.chart.hideLoading();
        this.requestInProgressMap = false;
        this.requestInProgressGraph = false;
      }
    },
    populateEnergyGraph() {
      let modules;
      if (this.mapped) modules = this.mappedModules.map(({ uuid }) => uuid);
      else modules = this.unmappedModules.map(({ uuid }) => uuid);

      const series = [];
      Object.keys(this.siteEnergyData.graph)
        .filter(moduleUuid => modules.includes(moduleUuid))
        .forEach((moduleUuid) => {
          const solarModule = this.getSolarModuleByUuid(moduleUuid);
          series.push({ name: `Module ${this.getFullModuleName(solarModule)}`, data: this.siteEnergyData.graph[moduleUuid] });
        });

      this.$set(this.energyGraph, 'series', series);
    },
    async getSiteStatusData() {
      if (!this.selectedSite) return;
      this.siteStatusData = { today: [], month: [], totalEnergy: [] };

      try {
        this.requestInProgressSiteStatus = true;
        const now = moment().tz(this.selectedSite.timezone);
        const todayQuery = { from: now.startOf('day').toISOString(true), to: now.endOf('day').toISOString(true), period: 'daily' };
        const monthQuery = { from: now.startOf('month').toISOString(true), to: now.endOf('month').toISOString(true), period: 'monthly' };
        const [today, month, totalEnergy] = await Promise.all([
          this.$daqApi.get(`/sites/${this.selectedSite.id}/energy`, { query: todayQuery }),
          this.$daqApi.get(`/sites/${this.selectedSite.id}/energy`, { query: monthQuery }),
          this.$daqApi.get(`/sites/${this.selectedSite.id}/energy/total`)
        ]);

        this.siteStatusData = { today, month, totalEnergy };
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.requestInProgressSiteStatus = false;
      }
    },
    setCurrentPowerInterval() {
      if (!this.selectedSite || !this.isComponentActive) return;
      this.clearCurrentPowerInterval();
      this.siteLinks.forEach(link => this.getCurrentPower(link));
      currentPowerInterval = setInterval(() => this.siteLinks.forEach(link => this.getCurrentPower(link)), 60000);
    },
    clearCurrentPowerInterval() {
      if (currentPowerInterval != null) {
        clearInterval(currentPowerInterval);
        currentPowerInterval = null;
      }
    },
    async getCurrentPower(link) {
      if (!this.selectedSite || !this.isComponentActive) return;
      const gateway = this.getGatewayByUuid(link.gateway.uuid);
      if (gateway) {
        const gatewayStatus = this.getGatewayStatus(gateway.code);
        const gatewayClient = await this.$broker.getGatewayClient(gateway);
        if (gatewayStatus === 'ONLINE') {
          try {
            const { data } = await gatewayClient.modules.getOperatingPower({ moduleUuid: link.module.uuid });
            this.$set(this.currentPower, link.module.uuid, data.Power);
          } catch (e) {
            this.$set(this.currentPower, link.module.uuid, 0);
          }
        } else {
          this.$set(this.currentPower, link.module.uuid, 0);
        }
      }
    }
  },
  created() {
    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);
  },
  watch: {
    solarModuleViewChoices: {
      immediate: true,
      handler() {
        this.selectedSolarModuleView = [];
        if (this.$refs.mappedSelector) {
          // Disables map zooming on view choice dropdown
          // eslint-disable-next-line no-undef
          L.DomEvent.disableScrollPropagation(this.$refs.mappedSelector);
          // eslint-disable-next-line no-undef
          L.DomEvent.disableClickPropagation(this.$refs.mappedSelector);
        }
      }
    },
    selectedSite: {
      immediate: true,
      handler(site) {
        if (site && site.timezone) this.$set(this.energyGraph, 'time', { timezone: site.timezone });

        if (site) {
          this.mapped = this.mappedModules.length > 0;
          this.siteEnergyData = { map: {}, graph: {} };
          this.siteStatusData = { today: [], month: [], totalEnergy: [] };
          this.currentPower = {};
        }
      }
    },
    displayId: {
      immediate: true,
      handler(id) {
        if (!id) return;
        this.$set(this.energyGraph, 'series', []);
        this.populateEnergyGraph();
      }
    },
    siteModulesInitialized: {
      immediate: true,
      handler(val) {
        const { siteId, initialized } = val;
        if (!siteId || !initialized) return;
        this.$set(this.energyGraph, 'series', []);
        this.getSiteStatusData();
        this.setCurrentPowerInterval();
        this.date = new Date();
      }
    },
    mappedModules(modules) {
      this.mapped = modules.length > 0;
    },
    userIdle() {
      if (this.userIdle) this.clearCurrentPowerInterval();
      else this.setCurrentPowerInterval();
    },
    gatewayStatus(newStatus, oldStatus) {
      Object.keys(newStatus).forEach((gatewayUuid) => {
        if (oldStatus[gatewayUuid] !== 'ONLINE' && newStatus[gatewayUuid] === 'ONLINE') {
          this.siteLinks.filter(link => link.gateway.uuid === gatewayUuid).forEach(link => this.getCurrentPower(link));
        }
      });
    },
    date: {
      immediate: true,
      handler() {
        this.getSiteEnergyData();
      }
    },
    timeline() {
      this.getSiteEnergyData();
    }
  },
  beforeDestroy() {
    this.clearCurrentPowerInterval();
  },
  activated() {
    this.isComponentActive = true;
    this.setCurrentPowerInterval();
  },
  deactivated() {
    this.isComponentActive = false;
    this.clearCurrentPowerInterval();
  }
};
</script>

<style lang="scss">

@import '~leaflet/dist/leaflet.css';
@import '~leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

.power-chart-card {
  flex-basis: 0;
  min-height: 400px;
}

.map-card {
  flex-basis: 0;
  min-height: 600px;
}

.site-summary-container {
  height: calc(100vh - 105px)
}

.highcharts-container {
  min-height: 0;
  min-width: 0;
}

.summary-radio label {
  cursor: pointer;
}

.summary-radio .active {
  background-color: $msi-orange !important;
  color: white !important;
  border-color: $msi-orange !important;
}

.overflow-hidden {
  overflow: hidden;
}

.module-view-multiselect {
  width: 200px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

.min-height-fit {
  min-height: fit-content;
}

.view-multiselect {
  width: 16rem;
}

.view-multiselect-show-all:hover {
  background: $msi-orange;
  color: white;
}
</style>
