<template>
  <div>
    <carousel :perPage="1" :paginationPadding="5" paginationPosition="bottom-overlay" class="weather-carousel"
      v-if="!requestInProgress && !error">
      <slide v-for="weatherDay in weatherForecast" :key="weatherDay.date" class="d-flex flex-column min-w-0">
        <b-row no-gutters class="flex-grow-1">
          <b-col cols="12" sm="6" md="12" xl="6" class="d-flex flex-column align-items-center p-1"
            :style="{ backgroundImage: getBackground(weatherDay.background), backgroundSize: 'cover' }">
            <h1 class="display-1 weather-date text-center pt-1 align-self-stretch">{{ weatherDay.date }}</h1>
            <font-awesome-icon class="weather-icon" :icon="weatherDay.icon"/>
            <h1 class="display-1 weather-temp mb-0">{{ weatherDay.temperature }}</h1>
            <h1 class="display-1 weather-state align-self-stretch">{{ weatherDay.summary }}</h1>
          </b-col>

          <b-col cols="12" sm="6" md="12" xl="6" class="d-flex flex-column justify-content-center px-1 py-3 mb-2">
            <div class="d-flex flex-row flex-wrap">
              <div class="weather-details-text text-bold">{{ $t("weather_carousel.cloud_cover") }}</div>
              <div class="weather-details-text">{{ weatherDay.cloudCover }}</div>
            </div>

            <div class="d-flex flex-row flex-wrap">
              <div class="weather-details-text text-bold">{{ $t("weather_carousel.wind") }}</div>
              <div class="weather-details-text">{{ weatherDay.windSpeed }}</div>
            </div>

            <div class="d-flex flex-row flex-wrap">
              <div class="weather-details-text text-bold">{{ $t("weather_carousel.precip_prob") }}</div>
              <div class="weather-details-text">{{ weatherDay.precipProbability }}</div>
            </div>

            <div class="d-flex flex-row flex-wrap">
              <div class="weather-details-text text-bold">{{ $t("weather_carousel.min_temp") }}</div>
              <div class="weather-details-text">{{ weatherDay.minTemperature }}</div>
            </div>

            <div class="d-flex flex-row flex-wrap">
              <div class="weather-details-text text-bold">{{ $t("weather_carousel.max_temp") }}</div>
              <div class="weather-details-text">{{ weatherDay.maxTemperature }}</div>
            </div>
          </b-col>
        </b-row>
      </slide>
    </carousel>

    <a href="https://openweathermap.org/" class="openweather-link" target="_blank" rel="noreferrer noopener"
      v-if="!requestInProgress && !error">
      powered by OpenWeather
    </a>

    <div v-if="requestInProgress" class="request-in-progress d-flex justify-content-center align-items-center">
      <msi-spinner class="ml-1 no-select" :size="30" />
      <div class="ml-2">Loading Weather Data</div>
    </div>

    <div v-if="error" class="no-data d-flex justify-content-center align-items-center">
      <div>Failed to fetch weather data</div>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */

import { BRow, BCol } from 'bootstrap-vue';

import { Carousel, Slide } from './carousel';
import Forecast from '../helpers/Forecast';
import MsiSpinner from './MsiSpinner.vue';

export default {
  name: 'WeatherCarousel',
  components: {
    Carousel,
    Slide,
    MsiSpinner,
    BRow,
    BCol
  },
  props: {
    site: Object
  },
  data() {
    return {
      weatherForecast: [],
      requestInProgress: false,
      error: false
    };
  },
  methods: {
    getBackground(url) {
      const background = require(`../assets/img/${url}`);
      return `linear-gradient(rgba(20, 20, 20, 0.5), rgba(20, 20, 20, 0.5)), url('${background}')`;
    }
  },
  watch: {
    site: {
      immediate: true,
      async handler(site) {
        if (!site) return;

        this.requestInProgress = true;
        this.error = false;

        try {
          const forecast = await this.$daqApi.getWeather(site.id);
          this.weatherForecast = new Forecast(forecast, site.timezone).getForecast();
        } catch (e) {
          this.error = true;
          if (e.name !== 'ApiError') throw e;
        } finally {
          this.requestInProgress = false;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.weather-carousel {
  background: rgba(0, 0, 0, 0.774);
}

.weather-carousel-btn {
  cursor: pointer;
  user-select: none;
  color: #686767;
}

.weather-icon-container-left {
  background-color: #d6d7d8;
  border-radius: 0.25rem 0 0 0.25rem;
  border: 1px solid #afafaf;
}

.weather-icon-container-right {
  background-color: #d6d7d8;
  border-radius: 0 0.25rem 0.25rem 0;
  border: 1px solid #afafaf;
}

.weather-date {
  font-size: 1.3rem;
  color: rgb(255, 255, 255);
  text-align: center;
}

.weather-icon {
  height: 60px;
  width: 60px;
  color: rgb(255, 255, 255);
}

.weather-temp {
  font-size: 1.8rem;
  color: rgb(255, 255, 255);
  text-align: center;
}

.weather-state {
  font-size: 1rem;
  color: rgb(255, 255, 255);
  text-align: center;
}

.weather-details-col {
  background: rgba(0, 0, 0, 0.774);
}

.weather-details-text {
  text-align: center;
  color: #fff;
  flex-basis: 50%;
  font-weight: 400;
  font-size: 0.9rem;
}

.openweather-link {
  position: absolute;
  bottom: 0;
  right: 0;
  text-decoration: none;
  padding-bottom: 4px;
  padding-right: 6px;
  font-size: 0.65rem;
  color: $msi-white
}

.openweather-link:hover {
  color: $msi-orange;
}

.no-select {
  user-select: none;
}

.request-in-progress {
  background: rgba(0, 0, 0, 0.774);
  min-height: 185px;
  color: white;
}

.no-data {
  background: rgba(0, 0, 0, 0.774);
  min-height: 50px;
  color: white;
}
</style>
