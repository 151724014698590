<template>
  <g :id="cluster.id" class="cluster" ref="cluster">
    <circle cx="0" cy="0" r="50" fill="#F47216" fill-opacity="0.7" />
    <circle cx="0" cy="0" r="60" fill="#F47216" fill-opacity="0.6" />
    <text x="0" y="5" dy="-20" fill="#ffffff" text-anchor="middle" dominant-baseline="middle" font-size="33"
      font-family="Arial, Helvetica, sans-serif">
      {{ valueData | round }}
    </text>
    <text x="0" y="5" dy="15" fill="#ffffff" text-anchor="middle" dominant-baseline="middle" font-size="32"
      font-family="Arial, Helvetica, sans-serif">
      {{ unit }}
    </text>
    <foreignObject>
      <div v-if="ready" xmlns="http://www.w3.org/1999/xhtml"><slot></slot></div>
    </foreignObject>
  </g>
</template>

<script>
/* global L */
import { findRealParent } from 'vue2-leaflet';

import './L.FixedCustomSVG';
import { valueMagnitude, unitMagnitude } from './helpers';

export default {
  name: 'Cluster',
  props: {
    cluster: {
      type: Object
    },
    valueData: {
      type: Number,
      default: 0
    },
    valueType: {
      type: String
    }
  },
  data() {
    return {
      ready: false
    };
  },
  computed: {
    unit() {
      const { valueData, valueType } = this;
      const unit = valueType === 'power' ? 'W' : 'Wh';
      return unitMagnitude(valueData, unit);
    }
  },
  methods: {
    unbindTooltip() {
      const tooltip = this.mapObject ? this.mapObject.getTooltip() : null;
      if (tooltip) {
        this.mapObject.unbindTooltip();
      }
    },
    setup() {
      if (this.$el.parentNode) this.$el.parentNode.removeChild(this.$el);
      this.mapObject = L.fixedCustomSVG(this.$el, this.cluster.center, { width: 0.5, height: 0.5 });
      this.mapObject.on('click', e => this.$emit('click', e, this.cluster));
      this.parentContainer = findRealParent(this.$parent);
      this.parentContainer.addLayer(this, false);
      this.ready = true;
    }
  },
  mounted() {
    this.setup();
  },
  beforeDestroy() {
    this.parentContainer.removeLayer(this);
  },
  watch: {
    cluster() {
      this.setup();
    }
  },
  filters: {
    round: value => valueMagnitude(value)
  }
};
</script>

<style>
.leaflet-overlay-pane path,
.leaflet-marker-icon,
.leaflet-image-layer,
.module {
  cursor: pointer
}

.cluster {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
</style>
