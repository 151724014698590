<template>
  <carousel v-model="currentPage" :perPage="1" :paginationPadding="5" paginationPosition="bottom-overlay"
    class="cp-carousel flex-grow-1 d-flex flex-column min-height-0">
    <slide class="w-100 d-flex flex-column">
      <high-charts-responsive :class="{'hide-graph': !showSelectedGraph}" class="highcharts-container flex-grow-1"
        :options="lastNoonIV" ref="lastNoonIV" />
      <high-charts-responsive :class="{'hide-graph': showSelectedGraph}" class="highcharts-container flex-grow-1"
        :options="lastNoonIVHover" ref="lastNoonIVHover" />
    </slide>

    <slide class="w-100 d-flex flex-column">
      <high-charts-responsive :class="{'hide-graph': !showSelectedGraph}" class="highcharts-container flex-grow-1"
        :options="currentIV" ref="currentIV" />
      <high-charts-responsive :class="{'hide-graph': showSelectedGraph}" class="highcharts-container flex-grow-1"
      :options="currentIVHover" ref="currentIVHover" />
    </slide>

    <slide class="w-100 d-flex flex-column">
      <high-charts-responsive :class="{'hide-graph': !showSelectedGraph}" class="highcharts-container flex-grow-1"
      :options="currentIVLive" ref="currentIVLive" />
      <high-charts-responsive :class="{'hide-graph': showSelectedGraph}" class="highcharts-container flex-grow-1"
      :options="currentIVLiveHover" ref="currentIVLiveHover" />
    </slide>
  </carousel>
</template>

<script>
/* eslint-disable newline-per-chained-call */
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { get } from 'vuex-pathify';
import moment from 'moment-timezone';

import { Carousel, Slide } from './carousel';
// eslint-disable-next-line import/no-named-default
import { default as HighChartsResponsive, ivButtons } from './HighChartsResponsive.vue';
import { extractIVCurve } from './graphs/helpers';

const graphOptions = {
  series: [],
  chart: {
    type: 'scatter'
  },
  legend: { enabled: false },
  xAxis: { title: { text: 'Voltage' }, labels: { format: '{value}V' }, showEmpty: false },
  yAxis: { title: { text: 'Current' }, labels: { format: '{value}A' }, showEmpty: false },
  plotOptions: { series: { marker: { enabled: true }, lineWidth: 1, animation: false } },
  tooltip: {
    formatter: function formatter() {
      const { metaData } = this.series.options;
      return `<span style="font-size: 10px">${metaData.date}</span><br/><span style="color: ${this.point.color}">\u25CF</span>
      ${this.series.name}<br/>
      <b>V: </b><span style="color: #1cb150; font-weight: bold;">${this.point.x} V</span>
      <b>\xA0I: </b><span style="color: #1cb150; font-weight: bold;">${this.point.y} A</span><br/>
      <b>Pmp:</b> ${metaData.pmp} W<br/>
      <b>Imp:</b> ${metaData.imp} A<br/>
      <b>Isc:</b> ${metaData.isc} A<br/>
      <b>Voc:</b> ${metaData.voc} V<br/>
      <b>Vmp:</b> ${metaData.vmp} V<br/>
      <b>Fill Factor:</b> ${metaData.fillFactor}%<br/>`;
    }
  },
  responsive: { rules: [{ condition: { maxWidth: 420 }, chartOptions: { yAxis: { title: { text: '' } } } }] }
};

export default {
  name: 'CurrentPowerCarousel',
  components: {
    Carousel,
    Slide,
    HighChartsResponsive
  },
  props: {
    selectedSolarModules: Array,
    hoveredSolarModule: {
      validator: prop => typeof prop === 'object' || prop === null
    },
    currentPower: Object,
    infoActive: Boolean
  },
  data() {
    return {
      lastNoonIV: Object.assign(cloneDeep(graphOptions), { title: { text: 'Last Noon IVs' } }),
      currentIV: Object.assign(cloneDeep(graphOptions), { title: { text: 'IVs' } }),
      currentIVLive: Object.assign(cloneDeep(graphOptions), { title: { text: 'Live IVs' } }),
      lastNoonIVHover: Object.assign(cloneDeep(graphOptions), { title: { text: 'Last Noon IVs' } }),
      currentIVHover: Object.assign(cloneDeep(graphOptions), { title: { text: 'IVs' } }),
      currentIVLiveHover: Object.assign(cloneDeep(graphOptions), { title: { text: 'Live IVs' } }),
      currentPage: 0
    };
  },
  created() {
    this.$options.chartSeries = {};

    this.$set(this.lastNoonIV, 'exporting', {
      fallbackToExportServer: false,
      buttons: { contextButton: { menuItems: ivButtons } },
      filename: 'last-noon-ivs'
    });

    this.$set(this.currentIV, 'exporting', {
      fallbackToExportServer: false,
      buttons: { contextButton: { menuItems: ivButtons } },
      filename: 'current-ivs'
    });

    this.$set(this.currentIVLive, 'exporting', {
      fallbackToExportServer: false,
      buttons: { contextButton: { menuItems: ivButtons } },
      filename: 'current-live-ivs'
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.currentPage = 1;
    });
  },
  computed: {
    selectedSite: get('sites/selectedSite'),
    getSolarModuleByUuid: get('solarmodules/getSolarModuleByUuid'),
    showSelectedGraph() {
      return this.hoveredSolarModule === null || !this.infoActive;
    }
  },
  methods: {
    async requestIVSeries(modules) {
      if (modules.length === 0) return [];
      const series = [];
      const moduleIds = modules.map(solarModule => solarModule.uuid);
      const requests = moduleIds.map((moduleUuid) => {
        return this.$daqApi.getLatestIVCurve(this.selectedSite.id, { query: { moduleUuid } })
          .then(value => ({ status: 'fulfilled', value, moduleUuid }))
          .catch(() => ({ status: 'rejected' }));
      });

      const responses = await Promise.all(requests);
      const validResponses = responses.filter(response => response.status === 'fulfilled');
      if (validResponses.length > 0) {
        validResponses.forEach((response) => {
          const { value, moduleUuid: uuid } = response;
          const extractedIVCurve = extractIVCurve([value]);
          const { ivCurve, metaData } = extractedIVCurve;
          series.push({ moduleId: uuid, name: `Module ${this.getFullModuleName(this.getSolarModuleByUuid(uuid))}`, data: ivCurve, metaData });
        });
      }

      return series;
    },
    async requestLastNoonIVSeries(modules) {
      if (modules.length === 0) return [];
      const { from, to } = this.getLastNoonInterval();
      if (!from || !to) return null;

      const series = [];
      try {
        const moduleIds = modules.map(solarModule => solarModule.uuid);
        const requests = moduleIds.map(moduleUuid => this.$daqApi.getIVCurve(this.selectedSite.id, { query: { moduleUuid, from, to } }));
        const responses = await Promise.all(requests);

        if (responses.some(response => response.length > 0)) {
          responses.forEach((response, index) => {
            if (response[0]) {
              const uuid = moduleIds[index];
              const extractedIVCurve = extractIVCurve(response);
              const { ivCurve, metaData } = extractedIVCurve;
              series.push({ moduleId: uuid, name: `Module ${this.getFullModuleName(this.getSolarModuleByUuid(uuid))}`, data: ivCurve, metaData });
            }
          });
        }
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      }

      return series;
    },
    async requestCurrentIVSeries(modules) {
      if (!this.$options.currentIVSeries) {
        const series = await this.requestIVSeries(modules);
        this.$options.currentIVSeries = series;
        return series;
      }

      const { currentIVSeries } = this.$options;
      const moduleIds = modules.map(m => m.uuid);
      const cachedSeries = currentIVSeries.filter(s => moduleIds.includes(s.moduleId));
      const cachedModuleIds = cachedSeries.map(s => s.moduleId);
      const newModules = modules.filter(m => !cachedModuleIds.includes(m.uuid));
      const newSeries = await this.requestIVSeries(newModules);
      const series = cachedSeries.concat(newSeries);
      this.$options.currentIVSeries = series;
      return series;
    },
    plotSeries(graphKey, series){
      const options = this[graphKey];
      this.$set(options, 'series', []);
      if (series.length === 0) return;

      this.$nextTick(() => {
        this.$set(options, 'series', series);
      });
    },
    async plotIV(modules, graphKey, opKey) {
      const graph = this.$refs[graphKey];
      graph.chart.showLoading();
      const currentOpKey = modules.map(({ uuid }) => uuid).join(':');
      this.$options[opKey] = currentOpKey;

      let requestSeries;
      if (graphKey === 'lastNoonIV' || graphKey === 'lastNoonIVHover') {
        requestSeries = this.requestLastNoonIVSeries.bind(this);
      } else if (graphKey === 'currentIV') {
        requestSeries = this.requestCurrentIVSeries.bind(this);
      } else {
        requestSeries = this.requestIVSeries.bind(this);
      }

      const series = await requestSeries(modules);
      if (this.$options[opKey] === currentOpKey) {
        this.plotSeries(graphKey, series);
        graph.chart.hideLoading();
      }
    },
    async plotSelectionIV(graphKey){
      return this.plotIV(this.selectedSolarModules, graphKey, 'selectionOpKey');
    },
    async plotHoveredIV(graphKey) {
      return this.plotIV([this.hoveredSolarModule], graphKey, 'hoverOpKey');
    },
    getLastNoonInterval() {
      if (!this.selectedSite || !this.selectedSite.timezone) return { from: null, to: null };

      const currentTime = moment().tz(this.selectedSite.timezone);
      const noon = moment().tz(this.selectedSite.timezone).hour(12).minute(0).second(0).millisecond(0);

      let from;
      let to;
      if (currentTime.isAfter(noon)) {
        from = moment(noon).subtract(10, 'minutes').toISOString(true);
        to = moment(noon).add(10, 'minutes').toISOString(true);
      } else {
        const yesterdayNoon = moment(currentTime).subtract(1, 'days').hour(12).minute(0).second(0).millisecond(0);
        from = moment(yesterdayNoon).subtract(10, 'minutes').toISOString(true);
        to = moment(yesterdayNoon).add(10, 'minutes').toISOString(true);
      }

      return { from, to };
    }
  },
  watch: {
    selectedSolarModules: {
      immediate: true,
      async handler() {
        if (!this.$refs.currentIV || !this.$refs.lastNoonIV) {
          this.$nextTick(() => {
            this.plotSelectionIV('currentIV');
            this.plotSelectionIV('lastNoonIV');
            this.plotSelectionIV('currentIVLive');
          });
        } else {
          this.plotSelectionIV('currentIV');
          this.plotSelectionIV('lastNoonIV');
          this.plotSelectionIV('currentIVLive');
        }
      }
    },
    async hoveredSolarModule(newVal) {
      if (!this.infoActive) return;

      if (newVal) {
        this.plotHoveredIV('currentIVHover');
        this.plotHoveredIV('lastNoonIVHover');
        this.plotHoveredIV('currentIVLiveHover');
      } else {
        this.$set(this.currentIVHover, 'series', []);
        this.$set(this.lastNoonIVHover, 'series', []);
        this.$set(this.currentIVLiveHover, 'series', []);
        this.$refs.currentIVHover.chart.showLoading();
        this.$refs.lastNoonIVHover.chart.showLoading();
        this.$refs.currentIVLiveHover.chart.showLoading();
      }
    },
    async currentPower(newVal) {
      if (isEmpty(newVal) || this.selectedSolarModules.length === 0 || this.currentPage !== 2) return;
      this.plotSelectionIV('currentIVLive');
    }
  }
};
</script>

<style>
.min-height-0 {
  min-height: 0;
  overflow: hidden;
}

.highcharts-container {
  min-height: 0;
  min-width: 0;
}

.cp-carousel .VueCarousel-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.cp-carousel .VueCarousel-inner {
  height: 0 !important;
  flex-grow: 1;
}

.hide-graph {
  display: none !important;
}
</style>
