<template>
  <div v-if="open" class="context-menu" :style="style" @contextmenu.capture.prevent>
    <div class="header"><slot></slot></div>

    <ul v-for="(option, index) in options" :key="index">
      <li
        v-if="option.expanded"
        @mouseenter="(...args) => handleMouseEnter(index, ...args)"
        @mouseleave="handleMouseLeave"
        @click="handleClick(index)"
        class="d-flex flex-row align-items-center">

        <div class="flex-grow-1">{{ option.title }}</div>
        <font-awesome-icon class="contextmenu-icon" icon="caret-right" />

        <div class="context-menu-expanded" v-if="expanded" :style="expandedStyle" ref="cmExpanded">
          <ul>
            <li v-for="(value, i) in options[index].values" :key="i" @click="handleExpandedClick(index, i)">
              {{ value }}
            </li>
          </ul>
        </div>
      </li>
      <li v-else @click="handleClick(index)" style="padding-right: 15px;">
        {{ option.title }}
      </li>
    </ul>
  </div>
</template>

<script>
const PADDING = 25;
const EXPANDED_PADDING = -5;

export default {
  props: {
    open: {
      type: Boolean,
      default: false
    },
    xyPosition: {
      type: Object
    },
    options: {
      type: Array
    }
  },
  computed: {
    style() {
      return this.open ? { top: `${this.top}px`, left: `${this.left}px` } : null;
    },
    expandedStyle() {
      return this.open ? { top: `${this.expandedTop}px`, left: `${this.expandedLeft}px` } : null;
    }
  },
  data() {
    return {
      top: null,
      left: null,
      expandedTop: null,
      expandedLeft: null,
      expanded: false
    };
  },
  methods: {
    openMenu() {
      this.$nextTick(() => {
        this.positionMenu(this.xyPosition.y, this.xyPosition.x);
        this.$el.focus();
      });
    },
    positionMenu(top, left) {
      const { clientHeight: containerHeight, clientWidth: containerWidth } = this.$parent.$el;
      const minTop = PADDING;
      const minLeft = PADDING;
      const maxTop = containerHeight - this.$el.clientHeight - PADDING;
      const maxLeft = containerWidth - this.$el.clientWidth - PADDING;
      if (top <= minTop) top = minTop;
      if (left <= minLeft) left = minLeft;
      if (top > maxTop) top = maxTop;
      if (left > maxLeft) left = maxLeft;
      this.top = top;
      this.left = left;
    },
    handleMouseEnter(index, event) {
      this.expanded = true;
      this.$nextTick(() => {
        const { clientHeight: containerHeight, clientWidth: containerWidth } = this.$parent.$el;
        const { clientHeight: expandedHeight, clientWidth: expandedWidth } = this.$refs.cmExpanded[0];
        const menu = this.$el.getBoundingClientRect();
        const menuItem = event.target.getBoundingClientRect();
        let top = menuItem.top - menu.top;
        let left = menuItem.width + EXPANDED_PADDING;

        if (this.top + top + expandedHeight + PADDING > containerHeight) {
          top -= this.top + top + expandedHeight + PADDING - containerHeight;
        }

        if (this.left + left + expandedWidth + PADDING > containerWidth) {
          left = -expandedWidth - EXPANDED_PADDING;
        }

        this.expandedTop = top;
        this.expandedLeft = left;
      });
    },
    handleMouseLeave() {
      this.expanded = false;
      this.expandedTop = null;
      this.expandedLeft = null;
    },
    handleClick(index) {
      const option = this.options[index];
      if (option.expanded) return;

      this.$emit('menuclick', option.title);
    },
    handleExpandedClick(index, i) {
      this.expanded = false;
      this.expandedTop = null;
      this.expandedLeft = null;

      const option = this.options[index];
      this.$emit('menuclick', option.values[i]);
    }
  },
  watch: {
    xyPosition(value) {
      if (!this.open || !value) return;
      this.openMenu();
    }
  }
};
</script>

<style lang="scss" scoped>
.context-menu {
  background: #f7f7f7;
  border: 1px solid #bdbdbd;
  box-shadow: 2px 2px 5px #757575;
  display: block;
  margin: 0;
  padding: 0;
  position: absolute;
  max-width: 200px;
  z-index: 1001;
}

.context-menu .header {
  font-size: 0.8rem;
  font-weight: 600;
  padding: 5px 15px 5px 15px;
  background: #ebebeb;
  border-bottom: 1px solid #bdbdbd;;
}

.context-menu ul {
  list-style: none;
  padding: 0 0;
  margin: 0;
}

.context-menu li {
  list-style: none;
  padding: 5px 0px 5px 15px;
  margin: 0;
  font-size: 0.75rem;
  font-weight: 400;
  cursor: pointer;
}

.context-menu li:hover {
  background: $msi-orange;
  color: #fafafa;
}

.context-menu-expanded li {
  list-style: none;
  padding: 5px 0px 5px 15px;
  margin: 0;
  font-size: 0.75rem;
  font-weight: 400;
  cursor: pointer;
  color: #000000;
}

.context-menu-expanded li:hover {
  background: $msi-orange;
  color: #fafafa;
}

.contextmenu-icon {
  padding-right: 5px;
  width: 15px;
  height: 15px;
}
.context-menu-expanded {
  background: #f7f7f7;
  border: 1px solid #bdbdbd;
  box-shadow: 2px 2px 5px #757575;
  display: block;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 150px;
  z-index: 1002;
}
</style>
