<template>
  <div class="d-flex flex-column">
    <l-map
      :bounds="bounds"
      :padding="padding"
      :max-zoom="24"
      :options="mapOptions"
      ref="map"
      @zoomstart="handleCloseContextMenu"
      @movestart="handleCloseContextMenu"
      @click="handleMapClick"
      @resize="handleCloseContextMenu"
      @move="handleMove"
      @moveend="handleMoveEnd"
      @zoom="handleZoomChange"
      :noBlockingAnimations="true"
      class="flex-grow-1"
    >
      <l-tile-layer
        :url="mapped ? url : ''"
        :attribution="attribution"
        :options="tileLayerOptions">
      </l-tile-layer>

      <div>
        <div v-if="(flooredZoom > clusterZoom) || !mapped">
          <template v-for="(solarModuleString, stringUuid) in solarModuleStrings">
            <solar-module-string
              :key="stringUuid"
              :solarModules="mapSolarModuleStrings[stringUuid]"
              :angle="angle"
              :selectedSolarModules="selectedSolarModules[stringUuid]"
              :infoActive="infoActive"
              :panning="panning"
              :panelValues="panelValues"
              :heatmap="heatmap && panelValues.type === 'energy'"
              :gradientColors="gradientColors"
              :maxValue="maxValue"
              :gradientTypes="gradientTypes"
              :selectedSolarModuleType="Object.keys(gradientTypes)[selectedGradientType]"
              @click="(...args) => handleClick(stringUuid, ...args)"
              @contextmenu="(...args) => handleOpenContextMenu(stringUuid, ...args)"
              @hoveredSolarModule="handleHoveredSolarModule" />
          </template>
        </div>

        <div v-if="flooredZoom <= clusterZoom && mapped">
          <clusters
            :clusters="clusters"
            :clusterValues="clusterValues"
            :valueType="panelValues.type"
            :infoActive="infoActive"
            @clusterClick="handleClusterClick" />
        </div>
      </div>

      <l-control position="bottomleft">
        <b-button-group>
          <b-button aria-label="Home icon" @click="handleHome"><i class="icon-home" /></b-button>
          <b-button aria-label="Info icon" @click="handleInfo" :class="{'msi-btn': infoActive}"><i class="icon-info" /></b-button>
          <b-button aria-label="Tools icon" v-b-modal.settings-modal :disabled="configDisabled" v-if="heatmap"><i class="icon-wrench" /></b-button>
          <!-- <b-button @click="handleHeatmap" :class="{'msi-btn': heatmap}"><i class="icon-map" /></b-button> -->
        </b-button-group>
      </l-control>

      <l-control position="bottomright" v-if="!isMobile">
        <gradient
          v-if="heatmap"
          v-model="selectedGradientType"
          :gradientColors="gradientColors"
          :types="gradientTypes"
          :unit="'Wh'"/>
      </l-control>

      <l-control position="topright">
        <slot name="mapped-selector"><template></template></slot>
      </l-control>

      <div class="leaflet-control-loader d-flex align-items-center justify-content-center" v-if="loading">
        <msi-spinner :size="60" />
      </div>

      <context-menu v-if="!isMobile"
        :open="contextmenu.open"
        :xyPosition="contextmenu.pos"
        :options="contextMenuOptions"
        @menuclick="handleMenuClick"
        v-on-clickaway="handleCloseContextMenu">

        <div>Module {{ currentContextMenuSolarModule ? getFullModuleName(currentContextMenuSolarModule) : '' }}</div>
      </context-menu>

      <b-modal v-if="isMobile" hide-footer v-model="showModal" body-class="p-0"
        :title="`Module ${currentContextMenuSolarModule ? getFullModuleName(currentContextMenuSolarModule) : ''}`">
        <div class="d-flex flex-column">
          <div v-for="(option, index) in contextMenuModalOptions"
            :key="index"
            class="py-2 pl-2"
            :class="{ 'border-bottom': index < contextMenuModalOptions.length - 1}"
            @click="handleMenuClick(option)">
            {{ option }}
          </div>
        </div>
      </b-modal>
    </l-map>
    <b-modal id="settings-modal" title="Settings" size="lg" @ok="handleSettingsUpdate">
      <settings
        :gradientTypes="gradientTypes"
        ref="settings" />
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="secondary" aria-label="cancel" @click="cancel()">Cancel</b-button>
        <b-button variant="primary" aria-label="ok" @click="ok()">Ok</b-button>
      </template>
    </b-modal>

    <div class="empty-map-message" v-if="!loading && !Object.keys(solarModuleStrings).length">
      The site has no modules to display
    </div>
  </div>
</template>

<script>
import { LMap, LTileLayer, LControl } from 'vue2-leaflet';
import max from 'lodash/max';
import isEmpty from 'lodash/isEmpty';
import ResizeObserver from 'resize-observer-polyfill';
import { get } from 'vuex-pathify';
import { mixin as clickaway } from 'vue-clickaway2';
import { BButton, BModal, BButtonGroup } from 'bootstrap-vue';
import Supercluster from 'supercluster';

import SolarModuleString from './SolarModuleString.vue';
import Clusters from './Clusters.vue';
import ContextMenu from './ContextMenu.vue';
import MsiSpinner from '../MsiSpinner.vue';
import Gradient from './Gradient.vue';
import Settings from './Settings.vue';
import { computeMapBounds, computePredictedEnergyGenerationPerModuleType, layOutStrings } from './helpers';
import { throttled } from '../../helpers/helpers';

export default {
  name: 'SiteMap',
  components: {
    LMap,
    LTileLayer,
    LControl,
    SolarModuleString,
    Clusters,
    ContextMenu,
    MsiSpinner,
    Gradient,
    Settings,
    BButton,
    BModal,
    BButtonGroup
  },
  mixins: [clickaway],
  props: {
    solarModuleStrings: {
      type: Object
    },
    mapped: {
      type: Boolean,
      default: false
    },
    angle: {
      type: Number,
      default: 0
    },
    displayId: {
      type: String
    },
    panelValues: {
      type: Object
    },
    from: {
      type: String
    },
    to: {
      type: String
    },
    loading: {
      type: Boolean
    }
  },
  data() {
    const mapboxToken = process.env.VUE_APP_MAPBOX_API_KEY;
    return {
      url: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${mapboxToken}`,
      attribution: `<a href="https://www.mapbox.com/about/maps/"
      target="_blank" rel="noreferrer noopener">&copy; Mapbox &copy; OpenStreetMap</a> <a class="mapbox-improve-map"
      href="https://www.mapbox.com/map-feedback/" target="_blank" rel="noreferrer noopener">Improve this map</a>`,
      mapOptions: {
        zoomControl: false,
        zoomDelta: 0.5,
        zoomSnap: 0.1,
        wheelPxPerZoomLevel: 240,
        doubleClickZoom: false,
        gestureHandling: this.$feature.mobile
      },
      tileLayerOptions: { maxZoom: 24, maxNativeZoom: 22 },
      selectedSolarModules: {},
      infoActive: false,
      modifiedGradientTypes: null,
      gradientColors: ['#808080', 'green', '#6CD129'],
      selectedGradientType: 0,
      contextmenu: {
        open: false,
        pos: null
      },
      showModal: false,
      contextMenuOptions: [
        { title: 'Add to Selection', expanded: false },
        { title: 'Graph', expanded: true, values: ['Compare IV', 'Live IV', 'Imp', 'Isc', 'Pmp', 'Vmp', 'Voc'] }
      ],
      currentContextMenuSolarModule: null,
      panning: false,
      contextMenuModalOptions: ['Add to Selection', 'Compare IV', 'Live IV', 'Imp', 'Isc', 'Pmp', 'Vmp', 'Voc'],
      zoom: 0,
      padding: [50, 50],
      animationDuration: 0.6,
      clusters: [],
      clusterZoom: 20,
      isComponentActive: true
    };
  },
  mounted() {
    if (!this.$options.resizeObserver){
      this.$options.resizeObserver = new ResizeObserver(throttled(() => {
        this.$refs.map.mapObject.invalidateSize({ animate: false });
      }));
    }

    this.$options.resizeObserver.observe(this.$refs.map.$el);
  },
  beforeDestroy() {
    this.$options.resizeObserver.unobserve(this.$refs.map.$el);
  },
  computed: {
    selectedSite: get('sites/selectedSite'),
    gradientType() {
      if (!this.selectedGradientType) return 1;
      return this.selectedGradientType;
    },
    mapSolarModuleStrings() {
      if (!this.solarModuleStrings) return null;
      if (this.mapped) return this.solarModuleStrings;

      const modules = Object.values(this.solarModuleStrings).flat();
      if (!modules.length) return {};
      const modulesWithGroup = modules.filter(m => m.groupUuid);
      const modulesWithoutGroup = modules.filter(m => !m.groupUuid);
      const groupedModules = modulesWithGroup.reduce((acc, cur) => {
        if (!acc[cur.groupUuid]) acc[cur.groupUuid] = [cur];
        else acc[cur.groupUuid].push(cur);
        return acc;
      }, {});

      if (modulesWithoutGroup.length) groupedModules.ungrouped = modulesWithoutGroup;
      return { undefined: Object.values(layOutStrings(groupedModules)).flat() };
    },
    bounds() {
      if (!this.mapSolarModuleStrings) return null;
      return computeMapBounds(this.mapSolarModuleStrings);
    },
    maxValue() {
      const values = Object.values(this.panelValues.data);
      if (values.length === 0) return 0;
      return max(values);
    },
    calculatedGradientTypes() {
      if (!this.solarModuleStrings || !this.selectedSite || this.panelValues.type !== 'energy') return {};
      const { data } = this.panelValues;
      const { coords } = this.selectedSite;
      const types = computePredictedEnergyGenerationPerModuleType(
        Object.values(this.mapSolarModuleStrings).reduce((acc, string) => acc.concat(string), []),
        data,
        this.from,
        this.to,
        { lat: coords.lat, lng: coords.lng },
        this.selectedSite.timezone
      );
      return Object.keys(types).reduce((acc, type) => {
        if (types[type].npr) {
          return { ...acc, [type]: types[type] };
        }
        return acc;
      }, {});
    },
    gradientTypes() {
      if (this.panelValues.type !== 'energy') return {};
      return this.modifiedGradientTypes || this.calculatedGradientTypes;
    },
    heatmap() {
      return Object.keys(this.gradientTypes).length > 0;
    },
    configDisabled() {
      return this.loading || this.panelValues.type !== 'energy';
    },
    isMobile() {
      return this.$feature.mobile;
    },
    flooredZoom() {
      return Math.floor(this.zoom);
    },
    clusterValues() {
      return this.clusters.reduce((acc, cur) => {
        const sum = cur.moduleUuids.reduce((a, uuid) => a + (this.panelValues.data[uuid] || 0), 0);
        acc[cur.id] = sum / cur.moduleUuids.length;
        return acc;
      }, {});
    }
  },
  methods: {
    handleClick(stringUuid, maintainStringStates, newSelectedSolarModules) {
      if (!maintainStringStates) {
        this.selectedSolarModules = {};
      }

      this.$set(this.selectedSolarModules, stringUuid, newSelectedSolarModules);

      const selectedSolarModules = {};
      Object.keys(this.selectedSolarModules).forEach((uuid) => {
        Object.assign(selectedSolarModules, this.selectedSolarModules[uuid]);
      });

      if (isEmpty(selectedSolarModules)) {
        this.$emit('selectedSolarModules', null);
      } else {
        this.$emit('selectedSolarModules', selectedSolarModules);
      }
    },
    handleHome() {
      if (this.$refs.map.mapObject && this.bounds) {
        this.$refs.map.mapObject.flyToBounds(this.bounds, { padding: this.padding, duration: this.animationDuration });
      }
    },
    handleInfo() {
      this.infoActive = !this.infoActive;
      this.$emit('infoActiveChange', this.infoActive);
    },
    handleOpenContextMenu(stringUuid, event, solarModule) {
      this.currentContextMenuSolarModule = solarModule;

      if (this.$feature.mobile) {
        this.showModal = true;
      } else {
        const { x, y } = event.containerPoint;

        this.contextmenu = {
          open: true,
          pos: { x, y }
        };
      }
    },
    handleCloseContextMenu() {
      this.currentContextMenuSolarModule = null;

      this.contextmenu = {
        open: false,
        pos: null
      };
    },
    handleMenuClick(title) {
      if (title === 'Add to Selection') {
        this.handleAddToSelection();
      } else {
        const selectedSolarModules = [];
        Object.keys(this.selectedSolarModules).forEach((stringUuid) => {
          Object.values(this.selectedSolarModules[stringUuid]).forEach((solarModule) => {
            selectedSolarModules.push(solarModule.uuid);
          });
        });

        if (this.currentContextMenuSolarModule) {
          const found = selectedSolarModules.find(uuid => uuid === this.currentContextMenuSolarModule.uuid);
          if (!found) selectedSolarModules.push(this.currentContextMenuSolarModule.uuid);
        }

        const { from, to } = this;

        if (title === 'Imp' || title === 'Isc' || title === 'Pmp' || title === 'Vmp' || title === 'Voc') {
          this.$router.push({ path: 'analysis', query: { graph: 'IvGraph', type: title, modules: selectedSolarModules, to, from } });
        } else if (title === 'Compare IV') {
          this.$router.push({ path: 'analysis', query: { graph: 'IvGraph', modules: selectedSolarModules, to, from } });
        } else if (title === 'Live IV') {
          this.$router.push({ path: 'live-iv', query: { graph: 'LiveIvGraph', modules: selectedSolarModules } });
        }
      }

      if (this.isMobile) {
        this.showModal = false;
      } else {
        this.handleCloseContextMenu();
      }
    },
    handleAddToSelection() {
      const solarModule = this.currentContextMenuSolarModule;

      if (solarModule) {
        if (this.selectedSolarModules[solarModule.stringUuid]) {
          if (this.selectedSolarModules[solarModule.stringUuid] && !this.selectedSolarModules[solarModule.stringUuid][solarModule.uuid]) {
            this.$set(this.selectedSolarModules[solarModule.stringUuid], solarModule.uuid, solarModule);
          }
        } else {
          this.$set(this.selectedSolarModules, solarModule.stringUuid, { [solarModule.uuid]: solarModule });
        }

        const selectedSolarModules = {};
        Object.keys(this.selectedSolarModules).forEach((stringUuid) => {
          Object.assign(selectedSolarModules, this.selectedSolarModules[stringUuid]);
        });

        if (isEmpty(selectedSolarModules)) {
          this.$emit('selectedSolarModules', null);
        } else {
          this.$emit('selectedSolarModules', selectedSolarModules);
        }
      }
    },
    handleMove() {
      if (!this.$feature.mobile) this.panning = true;
    },
    handleMoveEnd() {
      if (!this.$feature.mobile) this.panning = false;
    },
    handleHeatmap() {
      this.heatmap = !this.heatmap;
    },
    handleHoveredSolarModule(solarModule) {
      this.$emit('hoveredSolarModule', solarModule);
    },
    handleSettingsUpdate() {
      const { selectedGradientTypes } = this.$refs.settings;
      this.modifiedGradientTypes = selectedGradientTypes;
    },
    handleClusterClick(cluster) {
      if (this.$refs.map && this.$refs.map.mapObject) {
        const { padding, animationDuration: duration } = this;
        const zoom = this.$refs.map.mapObject.getBoundsZoom(cluster.bounds, false, padding);
        if (Math.floor(zoom) > this.clusterZoom) this.$refs.map.mapObject.flyToBounds(cluster.bounds, { padding, duration });
        else this.$refs.map.mapObject.flyTo(cluster.center, this.clusterZoom + 1, { duration });
      }
    },
    handleZoomChange(e) {
      this.zoom = e.target.getZoom();
    },
    handleMapClick() {
      if (this.contextmenu.open) {
        this.handleCloseContextMenu();
      } else {
        this.selectedSolarModules = {};
        this.$emit('selectedSolarModules', null);
      }
    }
  },
  activated() {
    this.isComponentActive = true;
    if (this.$refs.map && this.$refs.map.mapObject) {
      this.$refs.map.mapObject.invalidateSize();
      if (this.$options.siteChangedWhileInactive) {
        this.$options.siteChangedWhileInactive = false;
        // Hacky fix: invalidate size takes time to fix the map size, calling fitbounds won't work proeprly until the map finishes resizing
        setTimeout(() => {
          if (this.bounds) this.$refs.map.mapObject.fitBounds(this.bounds, { padding: this.padding });
        }, 100);
      }
    }
  },
  deactivated() {
    this.isComponentActive = false;
  },
  watch: {
    displayId() {
      this.selectedSolarModules = {};
      this.$emit('selectedSolarModules', null);
    },
    to() {
      this.modifiedGradientTypes = null;
    },
    from() {
      this.modifiedGradientTypes = null;
    },
    solarModuleStrings: {
      immediate: true,
      handler() {
        this.modifiedGradientTypes = null;
        this.clusters = [];
        if (!this.solarModuleStrings || !this.mapped) return;
        const modules = Object.values(this.solarModuleStrings).flat();
        const modulesWithGroup = modules.filter(m => m.groupUuid);
        const modulesWithoutGroup = modules.filter(m => !m.groupUuid);
        const groupedModules = modulesWithGroup.reduce((acc, cur) => {
          if (!acc[cur.groupUuid]) acc[cur.groupUuid] = [cur];
          else acc[cur.groupUuid].push(cur);
          return acc;
        }, {});

        const clusters = Object.keys(groupedModules).map((groupUuid) => {
          const bounds = computeMapBounds({ null: groupedModules[groupUuid] });
          return { groupUuid, center: bounds.getCenter(), bounds, id: groupUuid, moduleUuids: groupedModules[groupUuid].map(m => m.uuid) };
        });

        if (modulesWithoutGroup.length) {
          const supercluster = new Supercluster({
            minZoom: this.clusterZoom,
            maxZoom: this.clusterZoom,
            radius: 200,
            map: props => ({ moduleUuids: [props.moduleUuid] }),
            reduce: (acc, props) => { acc.moduleUuids.push(props.moduleUuids[0]); }
          });

          const points = modulesWithoutGroup.map((m) => {
            return { type: 'Feature', geometry: { type: 'Point', coordinates: [m.coords.lng, m.coords.lat] }, properties: { moduleUuid: m.uuid } };
          });

          supercluster.load(points);
          const { _southWest, _northEast } = this.bounds;
          const bbox = [_southWest.lng, _southWest.lat, _northEast.lng, _northEast.lat];
          clusters.push(...supercluster.getClusters(bbox, this.clusterZoom).map((c) => {
            const center = { lng: c.geometry.coordinates[0], lat: c.geometry.coordinates[1] };
            let cluster;
            if (c.id == null) cluster = { id: c.properties.moduleUuid, moduleUuids: [c.properties.moduleUuid] };
            else cluster = { id: c.id, moduleUuids: c.properties.moduleUuids };
            const bounds = computeMapBounds({ null: cluster.moduleUuids.map(uuid => modules.find(m => m.uuid === uuid)) });
            return { groupUuid: null, center, bounds, ...cluster };
          }));
        }

        this.clusters = clusters;
      }
    },
    panelValues() {
      this.modifiedGradientTypes = null;
    },
    selectedSite() {
      if (!this.isComponentActive) this.$options.siteChangedWhileInactive = true;
    }
  }
};
</script>

<style scoped>
.leaflet-control-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -50px;
  height: 110px;
  width: 130px;
  border-radius: 10px;
  background: rgb(255, 255, 255);
  z-index: 1000;
}
</style>

<style>
.leaflet-container {
  background-color: rgb(232, 224, 216);
}

.leaflet-container::after {
  z-index: 1001;
}

.empty-map-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.9rem;
  font-weight: 700;
  color: #666;
  user-select: none;
}
</style>
